<template lang="html">
  <div class="stat-block">
    <p v-if="!tomorrowSchedRevenue && tomorrowSchedRevenue!==0"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
    <p class="snug">
      <strong>Tomorrow <span v-if="tomorrowTimestamp" class="light-weight">{{ tomorrowTimestamp }}</span></strong><br />
      <span v-if="tomorrowSchedRevenue && tomorrowSchedRevenue !== 'error' && tomorrowSchedRevenue >= dayRevGoalLeading">Sched. Production: ${{ Math.round(tomorrowSchedRevenue/1000*10)/10 }}k of ${{ Math.round(dayRevGoalLeading/1000*10)/10 }}k goal</span>
      <span v-if="tomorrowSchedRevenue && tomorrowSchedRevenue !== 'error' && tomorrowSchedRevenue < dayRevGoalLeading">Sched. Production: ${{ Math.round((tomorrowSchedRevenue)/1000*10)/10 }}k, ${{ Math.round((dayRevGoalLeading-tomorrowSchedRevenue)/1000*10)/10 }}k under goal</span>
      <em v-if="tomorrowSchedRevenue==0 || tomorrowSchedRevenue=='error'" class="small">no scheduled production data</em>
    </p>
    <div class="chart chart-bars chart-bar-stacked">
      <div class="bars-container d-flex flex-row">
        <div
          class="bar-container d-flex flex-row"
          :class="barColorClass"
        >
          <div class="bar text-right" :style="{width: barWidth + '%'}">
            <i v-if="tomorrowSchedRevenue >= dayRevGoalLeading" class="far fa-check-circle"></i>
          </div>
          <div v-if="barWidth<100" class="bar-remainder" :style="{width: (100-barWidth) + '%'}"></div>
        </div>
      </div> <!-- /.bars-container -->
      <div class="labels-x d-flex flex-row justify-content-between">
        <p class="sm">0</p>
        <p class="sm"><span>${{ Math.round(dayRevGoalLeading/1000*10)/10 }}k goal</span></p>
        <!-- <p class="sm"></p> -->
      </div> <!-- /.labels-x -->
    </div> <!-- /day-chart -->

  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import moment from 'moment'

export default {
  props: ['dayRevGoal','dayRevGoalLeading','activeLocationId','activeLocation','activeMonthlyRevenueGoal','tomorrowSchedRevenue'],
  components: {
    ListErrors,
  },
  data () {
    return {
      errors: [],
      tomorrowTimestamp: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    barColorClass() {
      if(!this.tomorrowSchedRevenue && this.tomorrowSchedRevenue !== 0 || !this.dayRevGoal) {
        return null;
      }
      if(this.tomorrowSchedRevenue >= (this.dayRevGoal*1.1999)) {
        return 'purple';
      }
      if(this.tomorrowSchedRevenue >= (this.dayRevGoal*1.1)) {
        return 'green';
      }
      if(this.tomorrowSchedRevenue >= (this.dayRevGoal*1.0)) {
        return 'blue';
      }
      if(this.tomorrowSchedRevenue >= (this.dayRevGoal*.9)) {
        return 'orange';
      }
      return 'red';
    },
    barWidth() {
      if(!this.tomorrowSchedRevenue && this.tomorrowSchedRevenue !== 0 || !this.dayRevGoal) {
        return 0;
      }
      if(this.tomorrowSchedRevenue >= (this.dayRevGoal*1.0999)) {
        return 100;
      }
      if(this.tomorrowSchedRevenue < (this.dayRevGoal*1.0999)) {
        return this.tomorrowSchedRevenue / (this.dayRevGoal*1.0999) * 100;
      }
    },
  },
  watch: {
    
  },
  methods: {
    
  },
  mounted() {
    this.tomorrowTimestamp = moment().add(1,'days').format('M/D/YY');
  },
}
</script>

<style lang="less" scoped>
.chart {
  margin-top: 10px;
}
</style>
