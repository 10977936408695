<template lang="html">
  <div class="col-team-growth-level">
    <h3>Team Growth Levels</h3>
    <p>Average growth level and percentage of team members at each growth level.</p>

    <p v-if="!activeLocationId || !activeLocation" class="light-weight"><em>Please select a location above.</em></p>

    <p v-if="activeLocationId && !userCount" class="light-weight"><em>No users found as this primary location.</em></p>

    <span v-if="activeLocationId && (!badges || !badges.length || (!this.userCount && this.userCount!==0))"><i class="far fa-fw fa-pulse fa-spinner"></i></span>

    
    <div v-if="activeLocationId && userCount && userCount>0 && badges && badges.length && avgGrowthLevel" class="chart chart-bars chart-team-growth">

      <div class="bars-container d-flex flex-row">

        <div
          v-for="(gLevel, index) in growthLevels"
          class="bar-container d-flex flex-column justify-content-end align-items-center text-center"
          @click="toggleActiveBar(index)"
          :class="{active: activeBar==index}"
          :style="{width: (100/growthLevels.length) + '%'}"
        >
          <p class="label" :style="{bottom: gLevel.percentage + '%'}">{{ gLevel.percentage.toFixed(2) }}%</p>
          <div class="bar" :style="[{height: gLevel.percentage + '%'},{backgroundColor: badges[index].badge_color}]"></div>
        </div>

        <div
          v-if="avgGrowthLevel && avgGroBgColor"
          class="bg-avg-growth"
          :style="[{width: avgGroWidth + '%'},{backgroundColor: avgGroBgColor}]"
        ></div> <!-- /.bg-avg-growth -->
        <p
          v-if="avgGrowthLevel && avgGroWidth && avgGroWidth>=33"
          class="label-avg-growth label-avg-growth-left text-right"
          :style="[{right: (100 - avgGroWidth) + '%'},{backgroundColor: avgGroBgColor}]"
          :class="{'label-avg-growth-opaque': growthLevels.some(g => g.percentage > 70)}"
        >
          <i class="far fa-arrow-to-right"></i><br>
          <span>Average: {{ avgGrowthLevel.toFixed(2) }}</span>
        </p> <!-- /.label-avg-growth-left -->
        <p
          v-if="avgGrowthLevel && avgGroWidth && avgGroWidth<33"
          class="label-avg-growth label-avg-growth-right text-left"
          :style="[{left: avgGroWidth + '%'},{backgroundColor: avgGroBgColor}]"
          :class="{'label-avg-growth-opaque': growthLevels.some(g => g.percentage > 70)}"
        >
          <i class="far fa-arrow-to-left"></i><br>
          <span>Average: {{ avgGrowthLevel.toFixed(2) }}</span>
        </p> <!-- /.label-avg-growth-left -->

      </div> <!-- /.bars-container -->
      
      <div class="labels-x d-flex flex-row">
        <div
          v-for="(gLevel, index) in growthLevels"
          class="bar-label d-flex flex-column justify-content-end align-items-center text-center"
          :style="{width: (100/growthLevels.length) + '%'}"
        >
          <badge :badge="badges[index]" class="badge-chart prev-sm" :size="'sm'" :disableTooltip="false"></badge>
          <p class="label-badge-value">{{ badges[index].badge_level }}</p>
        </div>
      </div> <!-- /.labels-x -->

    </div> <!-- /.chart -->
    

  </div> <!-- /.col -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Badge from '../components/Badge.vue'

export default {
  props: ['activeLocationId','activeLocation'],
  components: {
    Badge, 
  },
  data () {
    return {
      badges: null,
      userCount: null,
      avgGrowthLevel: null,
      growthLevels: [],
      activeBar: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    // decide avg growth bg color by comparing avg to array of badges
    avgGroBgColor() {
      // if avg is greater than highest level, fall back to it
      if(this.avgGrowthLevel >= this.badges[this.badges.length-1].badge_level) {
        return this.badges[this.badges.length-1].badge_color
      }
      // otherwise decide color
      if(this.badges && this.badges.length && this.avgGrowthLevel) {
        // find 1 badge level higher
        let b = this.badges.find((badge,index) => badge.badge_level > this.avgGrowthLevel);
        // subtract 1 level and return color
        return this.badges[b.badge_level-1].badge_color;
      }
      return null;
    },
    // calculate width of avg grow level bg element
    avgGroWidth() {
      if(!this.avgGrowthLevel || !this.badges || !this.badges.length) {
        return 0;
      }
      // if avg is grater than highest level, fall back to 100%
      if(this.avgGrowthLevel >= this.badges[this.badges.length-1].badge_level) {
        return 100;
      }
      // otherwise find percentage
      // percentage of growth level: avg over max growth level value
        // must add 1 to the max badge level value because they start at 0 / are zero/indexed
        // formatted as percentage
      let perc = ( 100 * this.avgGrowthLevel / (this.badges[this.badges.length-1].badge_level+1) );
      // offset is 1/2 of one equal H division of the chart
        // formatted as percentage
      let offset = 100/this.growthLevels.length/2;
      return perc + offset;
    },
  },
  watch: {
    activeLocationId() {
      if(this.activeLocationId && this.activeLocation && (!this.badges || !this.badges.length)) {
        this.loadBadges();
      }
      if(this.activeLocationId && this.activeLocation && this.badges && this.badges.length) {
        this.userCount = null;
        this.avgGrowthLevel = null;
        this.growthLevels = [];
        this.loadUsersWithBadges();
      }
    },
    badges() {
      if(this.badges && this.badges.length && !this.userCount && !this.avgGrowthLevel) {
        this.loadUsersWithBadges();
      }
    },
  },
  methods: {
    toggleActiveBar(barIndex) {
      if(this.activeBar == barIndex) {
        this.activeBar = null;
      } else {
        this.activeBar = barIndex;
      }
    },
    loadBadges() {
      // load badges from firestore, save to this.badges, overwrite default badge
      if(this.custom_claims && this.custom_claims.customerId) {
        Vue.firestore.doc(`customers/${this.custom_claims.customerId}/settings/badges`)
        .get()
        .then(badgesSnap => {
          // console.log(badgesSnap.data());
          if(badgesSnap.exists && badgesSnap.data().badges_list && badgesSnap.data().badges_list.length) {
            this.badges = badgesSnap.data().badges_list;
            console.log(`${this.badges.length} badges loaded`)
          }
        })
        .catch(err => console.log(err));
      }
    },
    loadUsersWithBadges() {
      if(!this.custom_claims || !this.custom_claims.customerId || !this.activeLocationId) {
        this.userCount = 0;
        this.avgGrowthLevel = null;
        this.growthLevels = [];
        return;
      }
      console.log('loading team stats')
      Vue.firestore.collection(`users/`)
          .where('customerId', '==', this.custom_claims.customerId)
          .where('status', '==', 'accepted')
          .where('primary_location', '==', this.activeLocationId)
          // .where('badge_level', '!=', false)
          .where('badge_level', '>=', 0)
          .get()
          .then(usersSnap => {
            if(usersSnap.empty) {
              console.log('no users found in StatsTeamGrowth.vue')
              this.userCount = 0;
              this.avgGrowthLevel = null;
              this.growthLevels = [];
              return;
            }
            this.userCount = usersSnap.docs.length;
            console.log('userCount', this.userCount)

            // calculate avg level for all loaded users
            this.avgGrowthLevel = usersSnap.docs.reduce((tot, u) => tot + u.data().badge_level, 0) / this.userCount;
            console.log('avgGrowthLevel',this.avgGrowthLevel)
            
            // calculate percent of team at each badge level
            this.badges.forEach(badge => {
              const perc_of_users = usersSnap.docs.filter((u) => u.data().badge_level == badge.badge_level).length / this.userCount * 100;
              console.log(`${Math.round(perc_of_users)}% at badge level ${badge.badge_level}`)
              this.growthLevels[badge.badge_level] = {
                percentage: perc_of_users,
                badge_level: badge.badge_level,
              };
            })

          })
          .catch(err => console.log(err));
    },
  },
  mounted () {
    
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

// extra padding for missing team health subtitle
.col-team-growth-level {
  padding-top: 29px;
}

.hr-chart {
  border-top: 3px solid @dark-color;
  margin: 50px 0 30px;
}
.subtitle {
  margin: 0 0 5px;
}
h3 {
  margin: 0 0 10px;
}

.chart-team-growth {
  padding-top: 15px;

  .bars-container {
    position: relative;
    overflow: hidden;
  }
  .bar-container {
    text-align: center;
    height: 150px;
  }
  .bar {
    width: 20px;
    margin: 0 auto; // center horizontally
  }
  .labels-x {
    padding-top: 5px;

    .bar-label {
    margin: 0 auto; // center horizontally
  }
  }
  .bar-container.active {
    cursor: pointer;

    .label {
      visibility: visible;
      opacity: 1;
    }
    .bar {
      background-color: @dark-color !important;
    }
  }
  // inside. bars-container
  .bg-avg-growth {
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
    // left: -25px; // subtract width of 1/4 of a bar-container
    z-index: -1;
    border-right: 2px solid @dark-color;
  }
  .label-avg-growth {
    position: absolute;
    top: 5px;
    line-height: 1em;
    // padding: 0 5px;
    padding: 5px;
    z-index: 5;
  }
  .label-avg-growth-right {
    margin-left: 0;
  }
  .label-avg-growth-left {
    margin-right: 2px;
  }
  .label-avg-growth-opaque { 
    border: 1px solid @dark-color;
  }
  .label-avg-growth-left.label-avg-growth-opaque {
    border-right: none;
  }
  .label-avg-growth-right.label-avg-growth-opaque {
    border-left: none;
  }
}
</style>
