<template lang="html">
  <div class="stat-block">
    <p v-if="prevWorkdayRevenue==null"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
    <p class="snug">
      <strong>Previous Workday <span v-if="prevWorkdayTimestamp" class="light-weight">{{ prevWorkdayTimestamp | prettyDateShort }}</span></strong><br />
      <span v-if="prevWorkdayRevenue && prevWorkdayRevenue !== 'error'">Completed Net Prod.: ${{ Math.round(prevWorkdayRevenue/1000*10)/10 }}k of ${{ Math.round(dayRevGoalLeading/1000*100)/100 }}k goal</span>
      <em v-if="prevWorkdayRevenue==0 || prevWorkdayRevenue=='error'" class="small">no production data in past 7 days</em>
    </p>
    <div class="chart chart-bars chart-bar-stacked">
      <div class="bars-container d-flex flex-row">
        <div
          class="bar-container d-flex flex-row"
          :class="barColorClass"
        >
          <div class="bar text-right" :style="{width: barWidth + '%'}">
            <i v-if="prevWorkdayRevenue >= dayRevGoalLeading" class="far fa-check-circle"></i>
          </div>
          <div v-if="barWidth<100" class="bar-remainder" :style="{width: (100-barWidth) + '%'}"></div>
        </div>
      </div> <!-- /.bars-container -->
      <div class="labels-x d-flex flex-row justify-content-between">
        <p class="sm">0</p>
        <p class="sm"><span>${{ Math.round(dayRevGoalLeading/1000*10)/10 }}k goal</span></p>
        <!-- <p class="sm"></p> -->
      </div> <!-- /.labels-x -->
    </div> <!-- /day-chart -->

  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import moment from 'moment'

export default {
  props: ['dayRevGoal','dayRevGoalLeading','activeLocationId','activeLocation','activeMonthlyRevenueGoal'],
  components: {
    ListErrors,
  },
  data () {
    return {
      errors: [],
      prevWorkdayTimestamp: null,
      prevWorkdayRevenue: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    barColorClass() {
      if(!this.prevWorkdayRevenue && this.prevWorkdayRevenue !== 0 || !this.dayRevGoal) {
        return null;
      }
      if(this.prevWorkdayRevenue >= (this.dayRevGoal*1.1999)) {
        return 'purple';
      }
      if(this.prevWorkdayRevenue >= (this.dayRevGoal*1.1)) {
        return 'green';
      }
      if(this.prevWorkdayRevenue >= (this.dayRevGoal*1.0)) {
        return 'blue';
      }
      if(this.prevWorkdayRevenue >= (this.dayRevGoal*.9)) {
        return 'orange';
      }
      return 'red';
    },
    barWidth() {
      if(!this.prevWorkdayRevenue && this.prevWorkdayRevenue !== 0 || !this.dayRevGoal) {
        return 0;
      }
      if(this.prevWorkdayRevenue >= (this.dayRevGoal*1.0999)) {
        return 100;
      }
      if(this.prevWorkdayRevenue < (this.dayRevGoal*1.0999)) {
        return this.prevWorkdayRevenue / (this.dayRevGoal*1.0999) * 100;
      }
    },
  },
  watch: {
    activeLocationId() {
      if(this.activeLocationId) {
        this.loadData();
      }
    },
  },
  methods: {
    loadData() {
      // console.log('loadData in StatsProdPrevDay.vue');

      if(!this.custom_claims || !this.custom_claims.customerId) {
        return;
      }

      const oneWeekAgo = moment().subtract(7,'days').hour(1).unix();

      Vue.firestore.doc(`customers/${this.custom_claims.customerId}/stats/practice_health_goal/`).collection(this.activeLocationId)
        .where('timestamp', '>=', oneWeekAgo)
        .orderBy('timestamp','desc')
        .get()
        .then(statsSnap => {
          // console.log('statsSnap.docs',statsSnap.docs)
          if(statsSnap.empty) {
            console.log("error: could not find net revenue stat for past week")
            return;
          }
          var largest = {
            day_revenue: 0,
            timestamp: null,
          };
          statsSnap.docs.forEach(snap => {
            // console.log(`${snap.data().day_revenue} - ${snap.data().datestamp}`);
            // update largest only once for first value > 0
            if(largest.day_revenue==0 && snap.data().day_revenue && snap.data().day_revenue !== 'error' && snap.data().day_revenue > largest.day_revenue) {
              largest.day_revenue = snap.data().day_revenue;
              largest.timestamp = snap.data().timestamp;
            }
          })
          this.prevWorkdayTimestamp = largest.timestamp;
          this.prevWorkdayRevenue = largest.day_revenue;
        })
        .catch(err => console.log(err));
    },
  },
  mounted() {
    if(this.activeLocationId) {
      this.loadData();
    }
  },
}
</script>

<style lang="less" scoped>
.chart {
  margin-top: 10px;
}
</style>
