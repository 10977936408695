<template lang="html">
  <layout-public class="sign-up">
    <div>

      <h2 v-if="!user">Join Your Team on Smiley</h2>

      <p v-if="loading && !oldUser"><i class="far fa-fw fa-pulse fa-spinner"></i></p>

      <list-errors :errors="errors"></list-errors>

      <div v-if="user" class="text-center">
        <h2>Welcome to Smiley!</h2>
        <p v-if="environment == 'production'">Visit <a href="https://team.hey-smiley.com" target="_blank">https://team.hey-smiley.com</a> or scan this code on your phone to get started!</p>
        <p v-if="environment == 'production'"><img src="../assets/img/qr-smiley-mobile-production.svg" alt="https://team.hey-smiley.com" width="100" height="100"></p>
        <p v-if="environment == 'staging' || environment == 'development'">Visit <a href="https://smiley-staging-mobile.web.app" target="_blank">https://smiley-staging-mobile.web.app</a> or scan this code on your phone to get started!</p>
        <p v-if="environment == 'staging' || environment == 'development'"><img src="../assets/img/qr-smiley-mobile-staging.svg" alt="https://smiley-staging-mobile.web.app" width="150" height="150"></p>
      </div>

      <div v-if="oldUser && !user" class="signup-email">
        <p class="form-control disabled" disabled> {{ oldUser.email }}</p>
        <input type="password" name="password" class="form-control" placeholder="Enter password" v-model="password" @keyup.enter="signUp">
        <label class="normal-weight form-check">
          <input type="checkbox" name="termsAccepted" v-model="termsAccepted" value="termsAccepted" class="form-check-input">
          <!-- TODO: update terms-of-service url -->
          <small>I agree to the <a href="https://healthassuranceplan.com/terms-of-service/" target="_blank">terms of service &amp; privacy policy</a></small>.
        </label>

        <div class="d-flex flex-row justify-content-between">
          <p v-if="!loading"><button class="btn btn-app" @click="signUp">Sign Up<i class="far fa-angle-right fa-fw"></i></button></p>
          <p v-if="loading"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
        </div>
      </div>

    </div>
  </layout-public>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPublic from '../components/LayoutPublic.vue'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: [''],
  data () {
    return {
      errors: [],
      loading: true,
      email: null,
      password: null,
      termsAccepted: false,
      customerId: null,
      oldUserId: null,
      oldUser: null,
    }
  },
  components: {
    LayoutPublic, ListErrors
  },
  computed: {
    ...mapGetters(['user']),
    environment() {
      if(process.env.VUE_APP_ENV === "staging") {
        return "staging";
      }
      if(process.env.NODE_ENV === "production") {
        return "production";
      }
      if(process.env.NODE_ENV === "development" || process.env.VUE_APP_ENV === "test") {
        return "development";
      }
    },
    hasInvite() {
      // if(this.$route.query.redirect && this.$route.query.redirect.includes('invite')) {
      if(this.$route.query.id && this.$route.query.code) {
        return true;
      }
      return false;
    },
  },
  watch: {
    // when user loads, save them to db
    async user() {
      console.log(this.user.uid);
      let newUserSnap = await Vue.firestore.collection('users')
        .doc(this.user.uid)
        .set({
          oldUserId: this.oldUserId,
          email: this.oldUser.email,
          customerId: this.oldUser.customerId,
          name: this.oldUser.name || null,
          status: "accepted", //overwrite
          points: 0,
        })
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.errors.push(err.message);
        });
    },
  },
  methods: {
    async loadInvite() {
      // exit if missing queryString params
      if(!this.hasInvite) {
        return;
      }
      this.oldUserId = this.$route.query.id;
      this.customerId = this.$route.query.code;
      // load user object
      let oldUserSnap = await Vue.firestore.collection('customers')
        .doc(this.customerId)
        .collection('users')
        .doc(this.oldUserId)
        .get();

      // console.log({oldUserSnap});
      if(oldUserSnap.exists && oldUserSnap.data().email) {
        this.oldUser = oldUserSnap.data();
      } else{
        this.errors.push("couldn't find that user or email");
      }
      this.loading = false;
    },
    // copied from SignUpView.vue
    async signUp () {
      this.loading = true;
      // reset errors
      this.errors = [];
      // if privacy policy not accepted, show error
      if(!this.termsAccepted) {
        this.errors.push("You must accept the terms of service and privacy policy.")
        this.loading = false;
        return;
      }
      if(this.oldUser.status !== 'invited') {
        this.errors.push("This invitation is no longer valid.")
        this.loading = false;
        return;
      }
      //  attempt signUp
      let result = await this.$firebase.signUp(this.oldUser.email, this.password)
      // if result doesn't contain user, it's an error
      // console.log(result);
      if(result && result.code) {
        if(result.code == "auth/invalid-email") {
          this.errors.push("Oops, that's not a valid email address.")
        }
        else if(result.code == "auth/weak-password") {
          this.errors.push("Oops, your password must be at least 6 characters.")
        }
        else if(result.code =="auth/email-already-in-use") {
          this.errors.push("Oops, that email address is already in use by another account.")
        }
        else {
          this.errors.push(result.message);
        }
      }
      // if no errors
      else {
        // send email verification
        this.$firebase.verifyEmail()
          .then(() => {
            // this.$router.replace(this.nextRoute)
          })
          .catch(err => console.log(err));
      }
      // set loading false after saving to db in watch: user
    },
  },
  mounted () {
    this.loadInvite();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";


</style>
