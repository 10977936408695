<template>
  <layout-private  :customer="customer">

      <div class="row">
        <div v-if="customer && !customer.open_dental_status" class="text-center col-title col-12 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3">
          <h4 class="light-weight snug">Getting Started: Step 3 of 5</h4>
          <h1>Connect OpenDental</h1>
        </div> <!-- /.col-x -->
        <div v-if="customer && !customer.open_dental_status" class="text-center col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
          <p><strong>Follow these steps for each of your locations</strong> to connect OpenDental. Smiley only reads data, never writes, so your data is safe.</p>
          <p v-if="!customer"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
        </div> <!-- /.col-x -->

        <div v-if="customer && customer.open_dental_status" class="col-12">
          <h1 class="title-short">OpenDental Connection</h1>
          <hr />
        </div> <!-- /.col-x -->

        <div class="text-center col-box col-12 col-md-6 col-lg-4">
          <div class="box-icon-floating">
            <div class="icon-floating d-flex align-items-center justify-content-center">
              <i class="fal fa-external-link"></i>
            </div>
            <h4 class="light-weight"><strong>1.</strong> Verify / Install OpenDental eConnector at each location</h4>
            <p>Install and verify the eConnector is running so that your location can connect to the official OpenDental API's secure servers.</p>
            <p class="snug"><a href="https://www.opendental.com/manual/econnector.html" target="_blank">View Instructions<i class="far fa-fw fa-external-link"></i></a></p>
          </div>
        </div> <!-- /.col-x -->
        <div class="text-center col-box col-12 col-md-6 col-lg-4">
          <div class="box-icon-floating">
            <div class="icon-floating d-flex align-items-center justify-content-center">
              <i class="fal fa-key"></i>
            </div>
            <h4 class="light-weight"><strong>2.</strong> Add the Smiley API key in OpenDental at each location</h4>
            <p>Click the "Get API Key" button next to each location that needs a Smiley API key, then copy and paste it inside OpenDental so that Smiley can read your data. Don't worry, Smiley cannot overwrite data.</p>
            <p class="snug"><a href="https://www.opendental.com/manual/fhir.html" target="_blank">View Instructions<i class="far fa-fw fa-external-link"></i></a></p>
          </div>
        </div> <!-- /.col-x -->
        <div class="text-center col-box col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-0">
          <div class="box-icon-floating">
            <div class="icon-floating d-flex align-items-center justify-content-center">
              <i class="fal fa-link"></i>
            </div>
            <h4 class="light-weight"><strong>3.</strong> Test the API connection for each location</h4>
            <p>Click the test button for each location to verify Smiley can connect to the API. Please note each test may take a few minutes.</p>
          </div>
        </div> <!-- /.col-x -->

        <div v-if="customer && locations && Object.keys(locations).length > 0" class="col-table col-12">
          <table class="table table-custom  table-striped-custom">
            <thead>
              <tr>
                <th>Location Name</th>
                <th v-if="customer && customer.open_dental_status">Last Used</th>
                <th>API Key</th>
                <th>Test API Connection</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(location, locationId) in locations" :key="locationId">
                <td>{{ location.locationName }}</td>
                <td v-if="customer && customer.open_dental_status"><p>{{ location.open_dental_last_used | prettyDateTime }} UTC</p></td>
                <td v-if="location.open_dental_customer_api_key"><p>{{ location.open_dental_customer_api_key }}</p> <copy-text :clipboardText="location.open_dental_customer_api_key"></copy-text></td>
                <td v-if="!location.open_dental_customer_api_key">
                  <button v-if="location.open_dental_status !== 'loading'" class="btn btn-link" @click="requestAPIKey(location)"><i class="far fa-fw fa-key"></i>Get API Key</button>
                  <p v-if="location.open_dental_status == 'loading'"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
                </td>
                <td v-if="location.open_dental_customer_api_key">
                  <em v-if="location.open_dental_status == 'active'" class="light-weight"><i class="far fa-fw fa-check color-green"></i>success</em>
                  <em v-if="location.open_dental_status == 'error'" class="light-weight"><i class="color-error far fa-fw fa-times"></i>error</em>
                  <button class="btn btn-link btn-test"
                    v-if="location.open_dental_status !== 'loading'"
                    @click="testLocationOpenDentalKey(locationId,location.groupId)"
                  >
                    <span v-if="!location.open_dental_status">test</span>
                    <em v-if="location.open_dental_status == 'error'" class="light-weight">retry</em>
                  </button>
                  <p v-if="location.open_dental_status == 'loading'"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="customer" class="col-footer col-12 text-center">
          <hr />
          <p v-if="errors.length">Error:</p>
          <list-errors :errors="errors" class="color-error"></list-errors>
          <p v-if="loading"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
          <p v-if="!customer.open_dental_status" class="snug">
            <button v-if="!loading" @click.prevent="$router.push('/roles')" :disabled="!allLocationsPassed" class="btn btn-app">Continue<i class="far fa-angle-right fa-fw"></i></button>
            <button v-if="!loading" @click.prevent="skipOpenDentalSetup" class="btn btn-dark">Skip</button>
          </p>
        </div>

      </div> <!-- /.row -->
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import CopyText from '../components/CopyText.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors, CopyText,
  },
  data () {
    return {
      errors: [],
      loading: false,
      // locations: [], // must be an array, objects of objects are not reactive
      locations: {}, // objects of objects are only reactive if set with this.$set(this.locations, name, value)
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    allLocationsPassed() {
      if(Object.keys(this.locations).length && Object.values(this.locations).every(location => location.open_dental_status && location.open_dental_status == "active")) {
        return true;
      }
      return false;
    },
  },
  watch: {
    customer() {
      this.loadGroupsLocations();
    },
    // check for redirect on loading custom claims
    custom_claims() {
      if(!this.custom_claims || !this.custom_claims.adminLevel || this.custom_claims.adminLevel !== "superAdmin") {
        this.$router.replace('/')
      }
    },
  },
  methods: {
    requestAPIKey(loc) {
      this.errors = [];
      const locationId = loc.id;
      const groupId = loc.groupId;
      if(!groupId || !locationId || !this.customer.id) {
        return;
      }
      this.$set(this.locations,locationId,{
        ...this.locations[locationId],
        open_dental_status: "loading",
      })
      var requestODAPIKey = Vue.functions.httpsCallable('requestODAPIKey');
      requestODAPIKey({ customerId: this.customer.id, groupId: groupId, locationId: locationId })
        .then(result => {
          // cloud function will update the db, so results will arrive via
            // onSnapshot, not here
          // console.log("requestODAPIKey result:",result);
          this.$set(this.locations,locationId,{
            ...this.locations[locationId],
            open_dental_status: null,
          })
        })
        .catch(err => {
          this.errors.push(err.message);
          console.log(err);
          this.$set(this.locations,locationId,{
            ...this.locations[locationId],
            open_dental_status: null,
          });
        });
    },
    skipOpenDentalSetup() {
      if(!this.customer.id) {
        this.errors.push('Error. Please refresh the page and try again.');
        return;
      }

      Vue.firestore.doc(`customers/${this.customer.id}`)
        .update({
          open_dental_status: "optOut",
        })
        .then(() => {
          console.log('opted out of open dental setup');
          this.$router.push('/roles');
        })
        .catch(err => console.error(err));
    },
    testLocationOpenDentalKey(locationId,groupId) {
      this.errors = [];
      // console.log(`customerId: ${this.customer.id}, groupId: ${groupId}, locationId: ${locationId}`)
      if(!groupId || !locationId || !this.customer.id) {
        this.errors.push('Error. Please refresh the page and try again.');
        return;
      }
      // set location as loading
      // let clickedLocationIndex = this.locations.findIndex(location => location.id == locationId);
      this.locations[locationId].open_dental_status = "loading";
      // this.locations[locationId] = {
      //   ...this.locations[clickedLocationIndex],
      //   open_dental_status: "loading",
      // }
      // callable cloud function w/ customerId (this.customer.id),locationId,groupId
      var tryOpenDentalLocationConnection = Vue.functions.httpsCallable('tryOpenDentalLocationConnection');
      tryOpenDentalLocationConnection({ customerId: this.customer.id, groupId: groupId, locationId: locationId })
        .then(result => {
          // cloud function will update the db, so results will arrive via
            // onSnapshot, not here
          console.log("tryOpenDentalLocationConnection result:",result);
        })
        .catch(err => {
          this.errors.push(err.message)
          console.log(err);
        });
    },
    async loadGroupsLocations() {
      this.errors = [];
      if(this.customer && !this.locations.length) {
        try {
          let groupsQuerySnap = await Vue.firestore.collection('customers')
            .doc(this.customer.id)
            .collection('groups')
            .get();

          // console.log({ groupsQuerySnap });
          if(groupsQuerySnap.empty) {
            throw new Error("Error: couldn't find location groups in your account.")
          }
          for(const group of groupsQuerySnap.docs) {
            // console.log(group.data())
            // onSnapShot is not async so cannnot await it
            group.ref.collection('locations')
              // .where('hidden','!=',true) // doesn't work, excludes null values
              .onSnapshot(locationQuerySnap => {
                // console.log({ locationQuerySnap });
                if(locationQuerySnap.empty) {
                  throw new Error("Error: couldn't find locations in your account.")
                }
                for(const [i,location] of locationQuerySnap.docs.entries()) {
                  // console.log(location.data(), location.id);
                  // do not add hidden locations
                  if(!location.data().hidden) {
                    // store locations with id so they can be updated with onSnapshot
                    // use $set to make reactive in Vue
                    this.$set(this.locations, location.id, {
                      ...location.data(),
                      groupId: group.id,
                    });
                  }
                }
              });
          }
        } catch(err) {
          this.errors.push(err.message)
          console.log(err);
        }
      }
    },
  },
  mounted () {
    this.loadGroupsLocations();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.col-box {
  margin-bottom: 75px;

  h4, p:not(p.snug) {
    margin-bottom: 30px;
  }
}
.col-footer {
  padding-bottom: 50px;
  .btn + .btn {
    margin-left: 20px;
  }
}
.col-table {
  margin-top: 40px;
  // margin-bottom: 30px;
}
table.table {
  margin: 0;

  p {
    margin: 0;
    display: inline-block;
  }
  tbody td {
    vertical-align: middle;
  }
}
// fix odd line-height
em + .btn-test {
  margin-left: 1em;
}
.btn-test {
  // display: block;
  vertical-align: baseline; // fix vertical alignment with p in table

  .far, .fal {
    margin: 0;
  }
}
</style>
