<template lang="html">
  <div class="col-12 col-xl-8">
    <hr class="hr-chart" />
    <div class="row">

      <div class="col-12 col-lg-6">
        <stats-caught-up
          :activeLocationId="activeLocationId"
          :activeLocation="activeLocation"
        ></stats-caught-up>
      </div> <!-- /.col -->

      <div class="col-12 col-lg-6">
        <stats-team-growth
          :activeLocationId="activeLocationId"
          :activeLocation="activeLocation"
        ></stats-team-growth>
      </div> <!-- /.col -->


    </div> <!-- /.row -->
  </div> <!-- /.col -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import StatsCaughtUp from '../components/StatsCaughtUp.vue'
import StatsTeamGrowth from '../components/StatsTeamGrowth.vue'

export default {
  props: ['activeLocationId', 'activeLocation'],
  components: {
    StatsCaughtUp,
    StatsTeamGrowth,
  },
  data () {
    return {
      errors: [],

    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),

  },
  watch: {
    // activeLocationId() {
    //   if(this.activeLocationId && this.activeLocation) {
        
    //   }
    // },
  },
  methods: {
    
  },
  mounted () {
    
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";


.hr-chart {
  border-top: 3px solid @dark-color;
  margin: 50px 0 30px;
}
</style>
