import { render, staticRenderFns } from "./CopyText.vue?vue&type=template&id=dc8756d6&scoped=true&lang=html&"
import script from "./CopyText.vue?vue&type=script&lang=js&"
export * from "./CopyText.vue?vue&type=script&lang=js&"
import style0 from "./CopyText.vue?vue&type=style&index=0&id=dc8756d6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc8756d6",
  null
  
)

export default component.exports