<template lang="html">
  <layout-public class="sign-up">
    <div>

      <div v-if="showSignUpWithGoogle" class="signup-google">
        <h1>Sign Up with Google</h1>
        <p>Sign up using your Google Account so Smiley can notify your team of new Google Reviews later. Make sure to use a Google Account that's an admin in Google My Business!</p>
        <label class="normal-weight form-check">
          <input type="checkbox" name="termsAccepted" v-model="termsAccepted" value="termsAccepted" class="form-check-input">
          <small>I agree to the <a href="/license/" target="_blank">terms &amp; conditions</a></small>.
        </label>
        <list-errors :errors="errors"></list-errors>
        <p v-if="loading"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
        <p v-if="!loading"><button class="btn btn-app btn-google" @click="signUpWithGoogle"><i class="fab fa-google fa-fw"></i>Sign Up with Google</button></p>
        <p v-if="!loading"><a href="#" @click="showSignUpWithGoogle=!showSignUpWithGoogle">Use email &amp; password instead</a></p>
      </div>

      <div v-if="!showSignUpWithGoogle" class="signup-email">
        <h1>Sign Up with Email</h1>
        <input type="text" name="email" class="form-control" placeholder="Enter email" v-model="email" @keyup.enter="signUp">
        <input type="password" name="password" class="form-control" placeholder="Enter password" v-model="password" @keyup.enter="signUp">
        <label class="normal-weight form-check">
          <input type="checkbox" name="termsAccepted" v-model="termsAccepted" value="termsAccepted" class="form-check-input">
          <small>I agree to the <a href="/license/" target="_blank">terms &amp; conditions</a></small>.
        </label>

        <list-errors :errors="errors"></list-errors>

        <div class="d-flex flex-row justify-content-between">
          <p v-if="!loading"><button class="btn btn-app" @click="signUp">Sign Up<i class="far fa-angle-right fa-fw"></i></button></p>
          <p v-if="loading"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
          <p><a href="#" @click="showSignUpWithGoogle=!showSignUpWithGoogle">Sign up with Google instead</a></p>
        </div>
      </div>


      <hr class="hr-dark-color hr-bottom-short" />

      <p>Already signed up? <router-link :to="{path: '/log-in', query: { redirect: this.$route.query.redirect } }">Log in</router-link>.</p>
    </div>
  </layout-public>
</template>

<script>
import {mapGetters} from 'vuex'
import LayoutPublic from '../components/LayoutPublic.vue'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: [''],
  data () {
    return {
      email: '',
      password: '',
      errors: [],
      termsAccepted: false,
      loading: false,
      showSignUpWithGoogle: true,
    }
  },
  components: {
    LayoutPublic, ListErrors
  },
  computed: {
    ...mapGetters(['user']),
    // get next route from querystring, set in FirebaseApp.js, or default
    nextRoute () {
      return '/subscription'
    },
  },
  watch: {
    //  when signUp completes, go to requested page
    user (auth) {
      if(auth) {
        this.$router.replace(this.nextRoute)
      }
    }
  },
  methods: {
    async signUpWithGoogle() {
      this.loading = true;
      // reset errors
      this.errors = [];
      // if privacy policy not accepted, show error
      if(!this.termsAccepted) {
        this.errors.push("You must accept the terms of service and privacy policy.")
        this.loading = false;
        return;
      }
      //  attempt logInWithGoogle
      let result = await this.$firebase.logInWithGoogle();
      if(result && result.code) {
        this.loading = false;
        this.errors.push(result.code);
      }

    },
    async signUp () {
      this.loading = true;
      // reset errors
      this.errors = [];
      // if privacy policy not accepted, show error
      if(!this.termsAccepted) {
        this.errors.push("You must accept the terms of service and privacy policy.")
        this.loading = false;
        return;
      }
      //  attempt signUp
      let result = await this.$firebase.signUp(this.email, this.password)
      // if result doesn't contain user, it's an error
      // console.log(result);
      if(result && result.code) {
        this.loading = false;
        if(result.code == "auth/invalid-email") {
          this.errors.push("Oops, that's not a valid email address.")
        }
        else if(result.code == "auth/weak-password") {
          this.errors.push("Oops, your password must be at least 6 characters.")
        }
        else if(result.code =="auth/email-already-in-use") {
          this.errors.push("Oops, that email address is already in use by another account.")
        }
        else {
          this.errors.push(result.message);
        }
      }
      // if no errors, send email verification
      else {
        this.$firebase.verifyEmail()
          .then(() => {
            // this.$router.replace(this.nextRoute)
          })
          .catch(err => console.log(err));
      }
    },
  },
  created () {
    if(this.user && this.$route.path !== this.nextRoute) {
      this.$router.replace(this.nextRoute)
    }
  },
  mounted () {
    if(this.user && this.$route.path !== this.nextRoute) {
      this.$router.replace(this.nextRoute)
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.form-check {
  margin-bottom: 30px;
}
hr.hr-form-footer {
  margin-bottom: 30px;
}
</style>
