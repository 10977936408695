<template>
  <div class="col-12">
    <div class="row">

      <div class="col-rewards-instructions col-12 col-lg-4">
        <div>
          <h4>Get in-person rewards the more Smileys you earn!</h4>
          <p>The Smiley Rewards Program encourages team members to use the app and stay connected. You can edit your list of rewards so everyone can see. Ways to earn Smileys (points) are not editable. Super Admins and Group Admins cannot earn rewards.</p>
        </div>
      </div> <!-- /.col -->

      <div class="col-rewards-list col-12 col-md-6 col-lg-4">
        <div class="d-flex flex-row justify-content-between">
          <h4 class="snug">Reward</h4>
          <p class="snug">needed <img class="icon-smiley-points" src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /></p>
        </div>
        <hr />
        
        <div v-if="!editingRewards">
          <div v-if="rewardsList && rewardsList.length" v-for="rew in rewardsListSorted" class="reward-item d-flex flex-row justify-content-between">
            <p class="snug">{{ rew.name }}</p>
            <p class="snug">{{ rew.points }}</p>
          </div>
          <p v-if="!rewardsList || !rewardsList.length" class="light-weight"><em>no rewards added</em></p>
          <p><button @click="editingRewards=true" class="btn btn-link"><i class="far fa-edit"></i>Edit Rewards</button></p>
        </div>

        <div v-if="editingRewards">
          <p><button @click="addReward" class="btn btn-link"><i class="far fa-plus"></i>Add Reward Item</button></p>

          <div v-if="rewardsList && rewardsList.length" v-for="(rew,indexRew) in rewardsList" class="form-row">
            <div class="col-9">
              <input v-model="rew.name" name="name" class="form-control" placeholder="Name" type="text"/>
            </div>
            <div class="col-3">
              <div class="input-group input-group-append">
                <input v-model="rew.points" name="points" class="form-control" placeholder="#" type="text"/>
                <button
                  class="btn btn-remove"
                  @click="removeReward(indexRew)"
                >
                  <i class="far fa-times"></i>
                </button>
              </div>
            </div>
            
          </div>
            
          <hr />
          <list-errors :errors="errors"></list-errors>
          <p v-if="success"><i class="far fa-fw fa-check green-color"></i> Updates Saved Successfully</p>
          <div class="d-flex flex-row justify-content-between">
            <p><button @click="editingRewards=false" class="btn btn-link"><i class="far fa-times"></i>cancel</button></p>
            <p><button @click="saveRewards" class="btn btn-app btn-icon-left"><i class="far fa-save"></i>Save Rewards</button></p>
          </div>
        </div>
      </div> <!-- /.col -->

      <div class="col-12 col-md-6 col-lg-4">
        <h4>How to Earn Smileys</h4>
        <hr />
        <ul class="list-unstyled">
          <li>5 <img class="icon-smiley-points" src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /> &mdash; Post/Receive a High Five</li>
          <li>5 <img class="icon-smiley-points" src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /> &mdash; Bump a message once a day</li>
          <li>10 <img class="icon-smiley-points" src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /> &mdash; Stay caught up in Smiley once a day</li>
          <li>20 <img class="icon-smiley-points" src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /> &mdash; Update your username to something other than your email address once</li>
          <li><em>More ways coming soon!</em></li>
        </ul>
      </div> <!-- /.col -->
    
    </div> <!-- /.row -->
  </div> <!-- /.col -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'

export default {
props: ['customer'],
components: {
  ListErrors
},
data () {
  return {
    errors: [],
    loading: false,
    editingRewards: false,
    rewardsList: [],
    success: false,
    oldRewardsList: [],
  }
},
computed: {
  ...mapGetters(['user', 'user_props', 'custom_claims']),
  rewardsListSorted() {
    return this.rewardsList.sort((prev, next) => next.points - prev.points);
  },
},
watch: {
  custom_claims () {
    this.getCustomerObject();
  },
},
methods: {
  getCustomerObject() {
    if(this.custom_claims && this.custom_claims.customerId) {
      Vue.firestore.doc(`customers/${this.custom_claims.customerId}`)
      .get()
      .then(custSnap => {
        // console.log(custSnap.data());
        if(custSnap.exists && custSnap.data().rewards_list) {
          this.rewardsList = custSnap.data().rewards_list;
          this.oldRewardsList = custSnap.data().rewards_list;
        }
      })
    }
  },
  addReward() {
    this.rewardsList.push({name:null, points:null})
  },
  removeReward(indexRew) {
    if(this.rewardsList[indexRew]) {
      this.rewardsList.splice(indexRew,1);
    }
  },
  saveRewards() {
    if(!this.custom_claims || !this.custom_claims.customerId) {
      return;
    }
    this.errors = [];
    this.success = false;
    if(!this.rewardsList.length) {
      this.errors.push('You must add rewards to save.')
    }
    if(this.rewardsList.length > 20) {
      this.errors.push('You can only have up to 20 rewards.')
    }
    if(this.rewardsList.some(rew => !rew.name || rew.name.trim().length < 3)) {
      this.errors.push('Reward names must be longer than 2 characters.')
    }
    if(this.rewardsList.some(rew => !rew.points || rew.points <= 0)) {
      this.errors.push('Reward point values must be greater than zero.')
    }
    if(this.rewardsList.every((e,i) => this.oldRewardsList[i] && e.name == this.oldRewardsList[i].name && e.points == this.oldRewardsList[i].points)) {
      this.errors.push('You must make edits to save.')
    }
    if(this.errors.length) {
      return;
    }
    Vue.firestore.doc(`customers/${this.custom_claims.customerId}`)
      .update({
        rewards_list: this.rewardsList,
      })
      .then(() => {
        console.log('saved rewards list');
        this.success = true;
        this.editingRewards = false;
        // convert to and from json to make copy of array
        this.oldRewardsList = JSON.parse(JSON.stringify(this.rewardsList));
      })
      .catch(err => {
        this.errors = [];
        this.errors.push(err);
      })
  },
},
mounted () {
  this.getCustomerObject();
},
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

h3,h4,p {
  margin: 0 0 20px;
}
hr {
  border-top: 1px solid @dark-color;
  margin: 20px 0;
}
.input-group {
  margin-bottom: 20px;
}
.icon-smiley-points {
  vertical-align: top;
}
ul.list-unstyled li {
  margin-bottom: 20px;
}
.reward-item {
  margin-bottom: 20px;
}
.col-rewards-instructions {
  // padding: 0;

  > div {
    background-color: fadeout(@color-blue, 50%);
    padding: 30px;
  }
}
</style>
