<template>
  <layout-private  :customer="customer">
    <div class="row">
      <div class="col-title col-12 text-center">
        <h4 class="light-weight snug">Getting Started: Step 1 of 5</h4>
        <h1>Choose Your Plan</h1>
        <list-errors :errors="errors"></list-errors>
      </div> <!-- /.col -->

      <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <div v-if="stripeProducts" v-for="(product, index) in stripeProducts" :key="product.id">
          <a
            class="product d-flex flex-row justify-content-between align-items-center"
            :class="[
              /* product.highlighted ? 'product-highlighted' : '', */
              selectedProduct && selectedProduct.id == product.id ? 'product-selected' : ''
              ]"
            :href="'subscription/?product=' + product.name"
            @click.prevent="selectProduct(product)"
          >
            <span class="title">
              <h3>
                <i class="selected far fa-fw fa-check"></i>
                <i v-if="index==0" class="icon-product far fa-store-alt"></i>
                <i v-if="index==1" class="icon-product far fa-users"></i>
                <i v-if="index==2" class="icon-product far fa-chart-network"></i>
                <strong>{{ product.name }}</strong> Plan
              </h3>
              <p class="details">{{ product.description }}</p>
            </span>
            <span class="text-right">
              <ul class="list-unstyled">
                <li v-for="price in product.prices">{{ price.unit_amount | prettyPrice }} / {{ price.interval }}</li>
              </ul>
            </span>
          </a>
        </div> <!-- /v-for -->
      </div> <!-- /.col-x -->

      <div class="col-12 text-center">
        <transition name="scaleFade">
          <p v-if="selectedProduct && !loading"><button class="btn btn-app" @click="openCheckoutSession">Sign Up<i class="far fa-angle-right fa-fw"></i></button></p>
        </transition>
        <p v-if="loading"><i class="far fa-fw fa-pulse fa-spinner"></i><br />loading secure checkout page</p>
      </div>

    </div> <!-- /.row -->
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors
  },
  data () {
    return {
      errors: [],
      loading: false,
      stripeProducts: [],
      selectedProduct: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
  },
  watch: {
    // check for redirect on customer loading
    customer() {
      this.checkCustomer();
    },
    // also check for redirect on loading custom claims
    custom_claims() {
      this.checkCustomer();
    },
  },
  methods: {
    // if a custoemr already exists, redirect to billing page
    checkCustomer() {
      // if an admin level is set, this page should not be used
        // blocks mobileOnly and groupAdmin
        // use 2nd conditional below to block superAdmin because they should use billing page
      if(this.custom_claims && this.custom_claims.adminLevel && this.custom_claims.adminLevel !== "superAdmin") {
        this.$router.replace('/')
      }
      // do not redirect while loading
        // false positive when openCheckoutSession in progress
        // customer object saved, but checkout session not loaded yet
        // can cause a redirect while waiting for checkout session
        // also, don't redirect if customer present but group_limit is not.
        // that condition could occur if user exited stripe checkout
      if((this.customer && this.customer.group_limit && !this.loading)) {
        this.$router.replace('/billing')
      }
    },
    selectProduct (product) {
      this.selectedProduct = product;
    },
    async openCheckoutSession () {
      this.errors = [];
      this.loading = true;
      if(!this.selectedProduct) {
        this.errors.push("Oops, you must select a plan first.")
        return;
      }
      const docRef = await Vue.firestore
        .collection('customers')
        .doc(this.user.uid)
        .collection('checkout_sessions')
        .add({
          // find just the first active price, does not support monthly/yearly
          price: this.selectedProduct.prices.find(price => price.active).id,
          // on success redirect to billing
          success_url: `${window.location.origin}/billing`,
          // on cancel, return to this subscription page
          cancel_url: window.location.href,
        })
        .catch((err) => { console.log(err); });
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          this.errors.push(`An error occured: ${error.message}`);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url);
        }
      });
    },
  },
  async mounted () {
    this.checkCustomer();

    // load products from Firestore, which is auto-synced to Stripe
    Vue.firestore.collection('products')
      .where('active', '==', true)
      .get()
      .then(async (snapshot) => {
        snapshot.forEach(async (doc) => {
          // console.log(doc)
          let product = {
            id: doc.id,
            ...doc.data(),
            prices: [],
          };
          const priceSnapshot = await doc.ref.collection('prices').where('active', '==', true).get();
          priceSnapshot.docs.forEach((priceDoc, index, array) => {
            product.prices.push({
              id: priceDoc.id,
              ...priceDoc.data()}
            );
            // console.log(priceDoc.id, ' => ', priceDoc.data());
            // save on last loop
            if(array.length-1 === index) {
              this.stripeProducts.push(product);
            }
          });
        })
      })
      .catch((err) => { console.log(err); });
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

// transition for button
// .scaleFade-leave-active.
.scaleFade-enter-active {
  transition: all .5s;
}
.scaleFade-enter, .scaleFade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(1.5);
}

a.product {
  display: block;
  padding: 20px;
  border: 1px solid @major-color;
  margin-bottom: 20px;
  position: relative;
  text-decoration: none;
  transition: all 250ms ease-out;
  background-color: @light-color;

  h3, ul li, p {
    color: @dark-color;
  }
  ul {
    margin: 0;
  }
  li:last-child {
    margin: 0;
  }
  p {
    margin: 0;
  }
  h3 {
    text-transform: capitalize;
    font-size: 24px;
    line-height: 1em;
    margin: 0;
  }
  .icon-product {
    font-size: 20px;
    margin-right: 15px;
  }
  .selected {
    // for animation
    opacity: 0;
    transform: scale(.5);
    transition: all 125ms ease-out;
    width: 0;
  }
  .details {
    display: none;
  }
}
a.product:hover {
  background-color: @color-green;
  border-color: @color-green;

  h3, ul li, p {
    // color: @light-color;
  }
}
a.product.product-selected {
  background-color: @dark-color;
  border-color: transparent;
  cursor: default;

  h3, ul li, p {
    color: @light-color;
  }
  h3 {
    margin: 0 0 10px;
  }
  .icon-product {
    display: none;
  }
  .selected {
    transform: scale(1);
    opacity: 1;
    width: 1.25em;
    margin-right: 5px;
  }
  .details {
    display: block;
  }
}
a.product.product-current {
  .details {
    display: block;
  }
}
a.product.product-current,
a.product.product-current:hover,
a.product.product-highlighted.product-current:hover {
  cursor: default;
  border-color: #ccc;
  background-color: @light-color;

  h3, ul li, p {
    color: @dark-color;
  }
  .current.tag {
    margin: 0 10px 10px 0;
    color: @light-color;
    background-color: @dark-color;
    padding: 10px;
    font-size: 14px;
    line-height: 1em;
    display: inline-block;
    vertical-align: middle;
  }
  h3 {
    display: inline-block;
    line-height: 24px;

    .icon-product {
      display: none;
    }
  }
}
.fa-spin {
  animation: fa-spin 1s infinite linear;
}
</style>
