<template>
  <layout-private
    :customer="customer"
    :pageTitle="'Rewards'"
    :showBackBtn="$route.name=='RewardsList'"
    v-on:back="handleBack"
  >
    <div class="row">

      <div class="rewards-root-header col-12 text-center">
        <h1 class="title-short">Rewards</h1>

        <hr />

        <ul class="nav nav-sub">
          <li class="nav-item"><router-link class="nav-link" to="/rewards" exact-active-class="active">Leaderboard</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/rewards-list" exact-active-class="active">Rewards List</router-link></li>
        </ul>
      </div>

      <router-view />

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors
  },
  data () {
    return {
      errors: [],
      loading: false,
      userList: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims', 'custom_claims']),
    
  },
  watch: {
    // check for redirect on loading custom claims
    custom_claims() {
      if(!this.custom_claims || !this.custom_claims.adminLevel || this.custom_claims.adminLevel !== "superAdmin") {
        this.$router.replace('/')
      }
    },
  },
  methods: {
    handleBack() {
      if(this.$route.name=='RewardsList') {
        this.$router.push('/rewards');
      }
    },
  },
  mounted () {
    
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";
.rewards-root-header {
  hr {
    margin-bottom: 20px;
  }
}
</style>
