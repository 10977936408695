<template>
  <layout-private
    :customer="customer"
    :pageTitle="providerUser && providerUser.name ? 'Provider Profile: ' + providerUser.name : 'Provider Profile'"
  >
    <div class="row">

      <div class="col-title col-12">
        <h1 class="title">Provider Profile</h1>
        <router-link to="/team" class="btn btn-link"><i class="far fa-angle-left"></i> Back to Team page</router-link>
      </div>

      <div v-if="providerUser" class="col-12 col-xl-4">
        <div class="col-inner-profile">
          <div v-if="userPhoto" class="img-profile-container">
            <badge v-if="providerBadge" :badge="providerBadge" class="badge-overlay  badge-overlay-md" :size="'lg'" :disableTooltip="false"></badge>
            <div
              class="img-profile img-profile-xl"
              :class="{'img-badge': providerBadge}"
              :style="{'border-color': providerBadge ? providerBadge.badge_color : 'transparent'}"
            >
              <img :src="userPhoto" class="img-fluid" alt="profile photo" />
            </div>
          </div>

          <h3 v-if="providerUser.name" class="snug">{{ providerUser.name }}</h3>
          <p v-if="providerBadge"><em>{{ providerBadge.badge_name }}</em></p>

          <p v-if="primaryLocationName" class="snug">Primary Location</p>
          <p v-if="primaryLocationName"><strong>{{ primaryLocationName }}</strong></p>

          <p v-if="providerUser.provider_code" class="snug">Open Dental Provider Code</p>
          <p v-if="providerUser.provider_code" class="snug"><strong>{{ providerUser.provider_code }}</strong></p>
          <p v-if="providerUser.provider_is_hyg"><em>hygienist</em></p>
        </div> <!-- /.col-inner -->
      </div> <!-- /.col -->

      <div v-if="providerUser" class="col-12 col-xl-8">
        <div class="col-inner-prov-stat">
          <stats-prov-care-progress :providerUid="providerUid" :providerUser="providerUser"></stats-prov-care-progress>
        </div> <!-- /.col-inner -->
      </div> <!-- /.col -->

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import Badge from '../components/Badge.vue'
import StatsProvCareProgress from '../components/StatsProvCareProgress.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors, Badge, StatsProvCareProgress
  },
  data () {
    return {
      errors: [],
      badges: null,
      loading: false,
      providerUid: null,
      providerUser: null,
      primaryLocationName: null,
      userPhoto: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims', 'custom_claims']),
    providerBadge() {
      if(this.providerUser && this.providerUser.badge_level && this.badges && this.badges.length && this.badges[this.providerUser.badge_level]) {
        return this.badges[this.providerUser.badge_level];
      }
      return null;
    },
  },
  watch: {
    // check for redirect on loading custom claims
    custom_claims() {
      if(!this.custom_claims || !this.custom_claims.adminLevel || this.custom_claims.adminLevel !== "superAdmin") {
        this.$router.replace('/')
      }
      // load on page refresh
      if(this.custom_claims && this.custom_claims.customerId && this.providerUid) {
        this.loadProviderUser();
      }
    },
    // when id found, load user object
    providerUid() {
      if(this.providerUid) {
        this.loadProviderUser();
      }
    },
    // when user object loaded, load initial stats
    providerUser() {
      if(this.providerUser) {
        if(this.providerUser.primary_location) {
          this.loadPrimaryLocation();
        }
        if(this.providerUser.profilePhotoStoragePath) {
          this.loadProfilePhoto();
        }
      }
    },
  },
  methods: {
    loadBadges() {
      console.log('loadBadges')
      // load badges from firestore, save to this.badges, overwrite default badge
      if(this.custom_claims && this.custom_claims.customerId) {
        Vue.firestore.doc(`customers/${this.custom_claims.customerId}/settings/badges`)
          .get()
          .then(badgesSnap => {
            console.log(badgesSnap.data());
            if(badgesSnap.exists && badgesSnap.data().badges_list && badgesSnap.data().badges_list.length) {
              this.badges = badgesSnap.data().badges_list;
            }
          })
          .catch(err => console.log(err));
      }
    },
    loadProviderUser() {
      // load user from under customer object
      if(this.custom_claims && this.custom_claims.customerId && this.custom_claims.adminLevel && this.custom_claims.adminLevel == "superAdmin") {
        Vue.firestore.doc(`customers/${this.custom_claims.customerId}/users/${this.providerUid}`)
          .get()
          .then(uSnap => {
            console.log(uSnap.data());
            if(!uSnap.exists || !uSnap.data().provider_code || !uSnap.data().primary_location){
              console.log(`user ${this.providerUid} is missing required parameters`)
            } else {
              this.providerUser = uSnap.data();
            }
          })
          .catch(err => console.log(err));
      }
    },
    loadPrimaryLocation() {
      if(this.custom_claims && this.custom_claims.customerId && this.custom_claims.adminLevel && this.custom_claims.adminLevel == "superAdmin" && this.providerUser && this.providerUser.primary_location) {
        // use collectionGroup query since we don't have groupId to load doc directly by path
        Vue.firestore.collectionGroup('locations')
          .where('customerId','==',this.custom_claims.customerId)
          .where('id','==',this.providerUser.primary_location)
          .get()
          .then(lSnaps => {
            // loop through results (should only be 1)
            lSnaps.forEach(lSnap => {
              console.log(lSnap.data());
              // if no name found yet
              if(!this.primaryLocationName) {
                if(!lSnap.exists || !lSnap.data().locationName || lSnap.data().hidden){
                  console.log(`location ${this.providerUser.primary_location} is hidden or has no name`)
                } else {
                  this.primaryLocationName = lSnap.data().locationName;
                }
              }
            })
          })
          .catch(err => console.log(err));
      }
    },
    loadProfilePhoto() {
      if(!this.providerUser || !this.providerUser.profilePhotoStoragePath) {
        console.log('not photo to load');
        return;
      }
      let storagePath = this.providerUser.profilePhotoStoragePath;
      const thumbStoragePath = storagePath.substring(0, storagePath.lastIndexOf(".")) + "_500x500" + storagePath.substring(storagePath.lastIndexOf("."));
      Vue.storage.ref().child(thumbStoragePath).getDownloadURL()
        .then((result) => {
          console.log(result)
          // only show if correct filetype
            // allow only jpg, png, gif, webp, avif, tiff
          if(result.includes('.jpg') || result.includes('.jpeg') || result.includes('.png') || result.includes('.gif') || result.includes('.webp') || result.includes('.avif') || result.includes('.tiff')) {
            this.userPhoto = result;
          }
        })
        .catch(e => console.log(e));
    },
  },
  mounted () {
    // set ID from route params
    if(this.$route.params.id) {
      this.providerUid = this.$route.params.id;
    }
    // load badges
    if(!this.badges || !this.badges.length) {
      this.loadBadges();
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.col-title {
  margin-bottom: 50px;
}
.col-inner-profile {
  background-color: fadeout(@color-blue, 50%);
  border-top: 3px solid @dark-color;
  padding: 30px;
}
.col-inner-prov-stat {
  border-top: 3px solid @dark-color;
  padding-top: 30px;
}
</style>
