<template>
  <layout-private :customer="customer">
    <div>
      <h1>Billing</h1>

      <hr />

      <h3>Current plan</h3>
      <p v-if="loading"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
      <div v-if="subscription && !loading">
        <stripe-product-preview :firstoreProductRef="subscription.product"></stripe-product-preview>

        <div v-if="subscription.cancel_at_period_end">
          <h3 class="margin-top">Cancelled</h3>
          <p>You can continue using Smiley until {{ subscription.current_period_end.seconds | prettyDate }}</p>
        </div>

        <div v-if="!subscription.cancel_at_period_end">
          <h3 class="margin-top">Next Payment</h3>
          <p>{{ subscription.current_period_end.seconds | prettyDate }}</p>
        </div>

      </div>

      <h3>Manage Billing</h3>
      <div v-if="custom_claims && custom_claims.customerId && user && user.uid && user.uid == custom_claims.customerId">
        <p>Securely manage payment methods, switch or cancel plans, and more at Stripe:</p>
      <p v-if="!loadingPortal"><button class="btn btn-app" @click="createPortalLink">Manage Billing<i class="far fa-angle-right fa-fw"></i></button></p>
      <p v-if="loadingPortal" class="alert alert-custom"><i class="far fa-fw fa-pulse fa-spinner"></i> <em>Please wait, and you'll be redirected to your billing management page</em></p>
      </div>
      <div v-if="!custom_claims || !custom_claims.customerId || !user || !user.uid || user.uid !== custom_claims.customerId">
        <p>Only the account owner can manage billing, review payment plans, and update cards.</p>
      </div>
      

      <h3>Payment History</h3>
      <p v-if="loadingPayments"><i class="far fa-fw fa-pulse fa-spinner"></i></p>
      <table v-if="payments && !loadingPayments" class="table table-responsive-lg">
        <tr v-for="payment in payments" :key="payment.id">
          <td>{{ payment.created | prettyDate }}</td>
          <td>{{ payment.status }}</td>
          <td>{{ payment.amount | prettyPrice }}</td>
          <td><a :href="payment.charges.data[0].receipt_url" target="_blank"><i class="far fa-fw fa-receipt"></i>receipt</a></td>
        </tr>
      </table>

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import StripeProductPreview from '../components/StripeProductPreview.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, StripeProductPreview
  },
  data () {
    return {
      errors: [],
      loading: true,
      loadingPayments: true,
      loadingPortal: false,
      subscription: null,
      payments: [],
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
  },
  watch: {
    customer() {
      if(this.customer) {
        if(!this.customer.group_limit) {
          // console.log('no customer group limit, redirecting to subscription page')
          this.$router.replace("/subscription?from=billing")
        }
        // get subscription
        this.getSubscription();
        // get payments
        this.getPayments();
      }
    },
    // will always load when logged in
    custom_claims() {
      // redirect non superAdmin users
      if(this.custom_claims && this.custom_claims.adminLevel && this.custom_claims.adminLevel !== "superAdmin") {
        this.$router.replace("/")
      }
      // redirect customers without account
      if(!this.customer || !this.custom_claims.customerId) {
        // console.log('no customer in custom claims, redirecting to subscription page')
        this.$router.replace("/subscription?from=billing")
      }
    },
  },
  methods: {
    getSubscription() {
      if(this.user) {
        Vue.firestore.collection('customers')
          .doc(this.customer.id)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active'])
          .onSnapshot(async (snapshot) => {
            // In this implementation we only expect one active or trialing subscription to exist.
            const doc = snapshot.docs[0];
            this.subscription = doc.data();
            // console.log('Subscription => ', doc.data());
            this.loading = false;
          });
      }
    },
    getPayments() {
      if(this.user && !this.payments.length) {
        Vue.firestore.collection('customers')
          .doc(this.customer.id)
          .collection('payments')
          .onSnapshot(async (snapshot) => {
            snapshot.forEach((doc) => {
              // console.log(doc.data())
              this.payments.push(doc.data());
            })
            this.loadingPayments = false;
          });
      }
    },
    async createPortalLink () {
      this.loadingPortal = true;
      const functionRef = Vue.functions.httpsCallable('ext-firestore-stripe-payments-createPortalLink');
      try {
        const { data } = await functionRef({
          returnUrl: window.location.href,
        });
        window.location.assign(data.url);
      } catch(err) {
        console.error(err);
      }
    },
  },
  mounted () {
    if(this.customer) {
      if(!this.customer.group_limit) {
        // console.log('no customer group limit in mounted, redirecting to subscription page')
        this.$router.replace("/subscription?from=billing")
      }
      // get subscription
      this.getSubscription();
      // get payments
      this.getPayments();
    }
  },
}
</script>

<style lang="less" scoped>

h3{
  margin: 50px 0 10px;
}
h3:first-child {
  margin-top: 0;
}
// override h3:first-child no margin inside divs
h3.margin-top {
  margin: 50px 0 10px !important;
}
</style>
