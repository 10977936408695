<template>
  <layout-private
    :customer="customer"
  >
    <div class="row">

      <div class="col-12">
        <h1 class="title-short">Team Member Badges</h1>
        <hr />
      </div> <!-- /.col -->

      <div class="col-lg-9">
        <table class="table table-responsive-lg table-custom">
            <thead>
              <tr>
                <th>Preview</th>
                <th>Icon</th>
                <th>Level</th>
                <th>Color</th>
                <th>Badge Name</th>
                <th class="light-weight">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(badge,index) in badges">
                <td><div :style="{visibility: badge.badge_icon ? 'visible': 'hidden'}" class="badge-preview d-flex align-items-center justify-content-center">
                  <badge :badge="badge" class="prev-sm" :size="'sm'" :disableTooltip="true"></badge>
                  <badge :badge="badge" class="prev-lg" :size="'lg'" :disableTooltip="true"></badge>
                </div></td>
                <td><icon-picker :activeIcon="badge.badge_icon" v-on:set-icon="badge.badge_icon=$event"></icon-picker></td>
                <td>{{ index }}</td>
                <td>
                  <input v-model="badge.badge_color" type="color" class="color-picker">
                </td>
                <td><input v-model="badge.badge_name" class="form-control" type="text" name="badgeName" placeholder="Enter name" :tabindex="index+1" /></td>
                <td><button @click="deleteBadge(index)" class="btn btn-remove"><i class="far fa-fw fa-times"></i></button></td>
              </tr>
            </tbody>
        </table>
      </div> <!-- /.col -->

      <div class="col-12">
        <list-errors :errors="errors" class="text-left"></list-errors>
        <hr />
      </div> <!-- /.col -->
      <div class="col-6">
        <button v-if="badges && badges.length < 10" @click.prevent="addBadge" class="btn btn-link">
          <i class="far fa-plus-circle"></i>Add Badge
        </button>
        <p v-if="badges && badges.length >= 10" class="snug small light-weight"><em>Limit of 10 badges reached.</em></p>
      </div> <!-- /.col -->
      <div class="col-6 text-right">
        <p v-if="success"><i class="far fa-fw fa-check color-green"></i> Updates Saved Successfully</p>
        <p v-if="errors && errors.length" class="sm color-error">Oops! Error. Please see message(s) above.</p>
        <button class="btn btn-app" @click="saveBadges">Save Changes<i class="far fa-angle-right fa-fw"></i></button>
      </div> <!-- /.col -->

    </div> <!-- /.row -->
  </layout-private>
</template>

<script>
import Vue from 'vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'
import IconPicker from '../components/IconPicker.vue'
import Badge from '../components/Badge.vue'

export default {
  props: ['customer'],
  components: {
    LayoutPrivate, ListErrors, IconPicker, Badge
  },
  data () {
    return {
      errors: [],
      loading: false,
      success: false,
      badges: [{
        badge_level: 0,
        badge_name: null,
        badge_color: '#DDDDDD',
        badge_icon: null,
      }],
      oldBadges: null,
      badgeColorSuggestions: ['#DDDDDD','#ffb684','#ffbd25','#e9f375','#a7ebff','#83ffb2','#B63E97'],
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
  },
  watch: {
    // check for redirect on loading custom claims
    custom_claims() {
      if(!this.custom_claims || !this.custom_claims.adminLevel || this.custom_claims.adminLevel !== "superAdmin") {
        this.$router.replace('/')
      }
      if(this.custom_claims && this.custom_claims.customerId) {
        this.loadBadges();
      }
    },
  },
  methods: {
    addBadge() {
      if(this.badges && this.badges.length < 10) {
        this.badges.push({
          badge_level: this.badges.length,
          badge_name: null,
          badge_color: this.badgeColorSuggestions[this.badges.length],
          badge_icon: null,
        });
      }
    },
    deleteBadge(index) {
      if(this.badges[index]) {
        this.badges.splice(index,1);
      }
    },
    saveBadges() {
      // save badges index as each badge's badge_level
      if(!this.custom_claims || !this.custom_claims.customerId) {
        return;
      }
      this.errors = [];
      this.success = false;
      if(!this.badges || !this.badges.length) {
        this.errors.push('You must add badges to save.')
      }
      if(this.badges.length > 10) {
        this.errors.push('You can only have up to 10 badges.')
      }
      if(this.badges.some(b => !b.badge_name || b.badge_name.trim().length < 3)) {
        this.errors.push('Badge names must be longer than 2 characters.')
      }
      if(this.badges.some(b => !b.badge_color || !b.badge_color.includes('#') || b.badge_color.length !==7)) {
        this.errors.push('All badges must have valid colors.')
      }
      if(this.badges.some(b => !b.badge_icon)) {
        this.errors.push('All badges must have icons.')
      }
      this.badges.forEach((b,i) => {
        if(b.badge_level !== i || !Number.isInteger(b.badge_level)) {
          this.errors.push('Invalid badge level. Please refresh the page and try again.')
        }
      });
      if(this.oldBadges && this.oldBadges.length && this.oldBadges.length == this.badges.length && this.badges.every((b,i) => this.oldBadges[i] && b.badge_name == this.oldBadges[i].badge_name && b.badge_color == this.oldBadges[i].badge_color && b.badge_icon == this.oldBadges[i].badge_icon && b.badge_level== this.oldBadges[i].badge_level)) {
        this.errors.push('You must make edits to save.')
      }
      if(this.errors.length) {
        return;
      }
      Vue.firestore.doc(`customers/${this.custom_claims.customerId}/settings/badges`)
        .set({
          badges_list: this.badges,
        })
        .then(() => {
          console.log('saved badges list');
          this.success = true;
          // convert to and from json to make copy of array
          this.oldBadges = JSON.parse(JSON.stringify(this.badges));
        })
        .catch(err => {
          this.errors = [];
          this.errors.push(err);
        })
    },
    loadBadges() {
      // load badges from firestore, save to this.badges, overwrite default badge
      if(this.custom_claims && this.custom_claims.customerId) {
        Vue.firestore.doc(`customers/${this.custom_claims.customerId}/settings/badges`)
        .get()
        .then(badgesSnap => {
          // console.log(badgesSnap.data());
          if(badgesSnap.exists && badgesSnap.data().badges_list && badgesSnap.data().badges_list.length) {
            this.badges = badgesSnap.data().badges_list;
            this.oldBadges = badgesSnap.data().badges_list;
          }
        })
      }
    },
  },
  mounted () {
    if(this.custom_claims && this.custom_claims.customerId) {
      this.loadBadges();
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.table-custom {
  .form-control {
    margin: 0;
  }
}
input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border-radius: 0;
  outline: none;
  border: 1px solid @dark-color;
  cursor: pointer;
  height: 50px;
  width: 50px;
  padding: 0;
  position: relative;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::-webkit-color-swatch{
  border: 0;
  border-radius: 0;
}
::-moz-color-swatch,
::-moz-focus-inner{
  border: 0;
}
::-moz-focus-inner{
  padding: 0;
}
input[type="color"]:before {
  content: '\f1fb'; // via font awesome
  font-size: 12px;
  line-height: 15px;
  padding-top: 2px;
  text-align: center;
  position: absolute;
  bottom: -1px;
  right: -1px;
  display: block;
  height: 20px;
  width: 20px;
  background-color: @light-color;
  border: 1px solid @dark-color;
  // font awesome 5
  font-family: "Font Awesome 5 Pro";
  -webkit-font-smoothing: antialiased;
  // display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  // line-height: 1;
}
.badge-preview {
  position: relative;
  width: 90px;
  height: 50px;
  border: 1px solid @dark-color;
  background-color: transparent;

  .badge-container {
    position: relative;
    z-index: 2;
  }
  .badge-container + .badge-container {
    margin-left: 10px;
  }
  &:after {
    content: '';
    display: block;
    width: 88px; // minus border
    height: 25px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: @dark-color;
  }
}
</style>
