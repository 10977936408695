<template lang="html">
  <p v-if="product" class="stripeProductPreview">
    <strong>{{ product.name }} Plan</strong><br />
    {{ product.description }}
  </p>
</template>

<script>
import Vue from 'vue'

export default {
  props: ['firstoreProductRef'],
  data () {
    return {
      product: null,
    }
  },
  mounted () {
    if(this.firstoreProductRef) {
      this.firstoreProductRef.get()
        .then((doc) => {
          if(doc.exists) {
            this.product = doc.data();
            // console.log("StripeProductPreview Product doc",doc.data())
          } else {
            console.log("StripeProductPreview: no such document")
          }
        })
        .catch((err) => {
            console.log("StripeProductPreview: Error getting document:", err);
        });
    }
  },
}
</script>

<style lang="less" scoped>
.stripeProductPreview {

}
</style>
