<template lang="html">
  <layout-public-wide class="log-in">
    <div>
      <h1>Smiley App License Agreement</h1>

      <p>This Smiley App and Services License Agreement (&ldquo;Agreement&rdquo;) is executed, entered into and delivered by and between
          PRACTICECHANNEL, LLC, an Delaware limited liability company (&ldquo;Practicechannel&rdquo;), and the legal entity of your dental group or practice
          (the &ldquo;Practice&rdquo;) upon registering for the system.
          Practicechannel and the Practice are collectively referred to in this Agreement as the &ldquo;Parties,&rdquo; and Practicechannel or the Practice may be individually
          identified in this Agreement as a &ldquo;Party.&rdquo; </p>
      <p>WHEREAS, Practicechannel has developed a system, which includes the software,
          documentation, technical services, and support described in the attached &ldquo;Exhibit
          A,&rdquo; which is designed for a dental practice to implement its independent
          Practicechannel Membership Plan (the &ldquo;Plan&rdquo;) for
          patients who are without dental insurance (the &ldquo;System&rdquo;); and </p>
      <p>WHEREAS, the Practice operates a dental practice and desires to purchase from
          Practicechannel the right to use the System in order to implement the Plan for the Practice&rsquo;s patients who
          are without dental insurance; </p>
      <p>NOW THEREFORE, the Parties, in consideration of the terms and conditions which are
          contained in this Agreement, the duties and obligations which the Parties will respectively perform pursuant to
          this Agreement and other good and valuable consideration acknowledged by the Parties, specifically agree as
          follows: </p>
      <h2>ARTICLE 1. LICENSE. </h2>
      <p>1.01 Grant of License. Practicechannel grants to the Practice, under the terms and conditions which are specified in this Agreement, a
          limited, non-transferable, and non-exclusive license to use the System during the Term of this Agreement. Nothing
          in this Agreement or otherwise constitutes a sale of the System or any part thereof. </p>
      <p>1.02 Installation and Acceptance. The Practice will install and
          implement the System as soon as reasonably practicable after the Execution Date of this Agreement, but in no event
          more than thirty (30) days after the Execution Date. After delivery of the System by Practicechannel and the
          successful completion of testing to ensure the System performs substantially as described in the Practicechannel
          Documents which are set forth in Exhibit A, the Practice will be
          deemed to have accepted the System. </p>
      <p>1.03 Authorized Users. The Practice shall implement reasonable
          safeguards to allow the System to be used by &ldquo;Authorized Users&rdquo; only. Authorized Users are the Practice&rsquo;s employees, agents, and contractors, who are
          pre-approved, in writing, by Practicechannel. </p>
      <p>1.04 Use Limitations. The Practice acknowledges that the
          System, either as a whole or any part thereof, is proprietary to Practicechannel, and that Practicechannel retains
          all right to and ownership of the System, or any portion thereof. The Practice shall not: (i) alter, enhance, make
          derivative works, or otherwise modify the System, or merge it into another program; or (ii) reverse engineer,
          disassemble, decompile, translate, or make any attempt to discover the source code or object code or methodology
          of the System; or (iii) sell, license, publish, display, distribute, disseminate, or otherwise transfer to a third
          party the System or any copy thereof, in whole or in part; or (iv) permit unauthorized persons or third parties to
          use or copy the System. </p>
      <p>1.05 Modifications and Updates. Practicechannel reserves the right from time to time, in its sole discretion, to revise, modify, enhance, or
          update all or any portion of the System, provided, however, that Practicechannel will use reasonable efforts to
          notify the Practice in advance of any such event with appropriate documentation and reasonable promptness. 
      </p>
      <p>1.06 Hardware, Operating System, and Internet Access. The
          Practice has the sole obligation to ensure that it has the computer hardware, operating system, and internet
          access which are necessary to properly operate the System. </p>
      <p>1.07 Taxes. The Practice is responsible for any state, local,
          or federal taxes which are or might become applicable to the transactions set forth in this Agreement. </p>
      <h2>ARTICLE 2. TERM AND TERMINATION OF AGREEMENT. </h2>
      <p>2.01 Term. The initial Term of
          this Agreement will commence on the Execution Date of this Agreement (the &ldquo;Term
          Commencement Date&rdquo;) and will expire three hundred sixty-five (365) days after such
          Term Commencement Date in the following calendar year (the &ldquo;Term Expiration
          Date&rdquo;), unless, as prescribed in this Article 2, this Agreement is terminated prior
          to that Term Expiration Date. </p>
      <p>2.02 Extension of the Term. Effective as of the Term Expiration
          Date which is described in Article 2.01, the Term of this Agreement will be deemed to have been automatically
          extended by the Parties in successive one (1) year periods, unless either Party notifies the other Party, at least
          thirty (30) days prior to the expiration of the then current one (1) year Term, that such Party is not electing to
          extend the Term an additional one (1) year period. Any extension of this Agreement pursuant to this Article 2.02
          will likewise be characterized and identified as the &ldquo;Term&rdquo; under this Agreement. </p>
      <p>2.03 Termination. This Agreement may be terminated prior to the
          Term Expiration Date which is identified in Article 2.01, or during any of the one (1) year extensions of the Term
          pursuant to Article 2.02, in the following manner: </p>
      <p>(i) Early Termination. If the Parties
          consent and mutually agree, in writing, this Agreement may be terminated on the terms and effective as of the date
          stipulated by the Parties. </p>
      <p>(ii) Default. In the event either Party
          notifies the other Party that such other Party has defaulted in the performance of any duty or obligation which
          the defaulting Party is required to perform under this Agreement, and that default has not been cured within
          thirty (30) days following the delivery of the notice, the Party delivering the notice, if that Party so elects,
          may immediately terminate this Agreement. </p>
      <p>2.04 Effects of Termination. If this Agreement is terminated,
          pursuant to any of the provisions of this Article 2: (i) neither Party will be further obligated to perform under
          this Agreement, except for payment obligations which have accrued or other obligations which expressly survive
          termination under this Agreement; (ii) the Practice will immediately cease utilizing the System; (iii) the license
          granted to the Practice by this Agreement will be deemed to be revoked; and (iv) the Practice will timely deliver,
          to or at the direction of Practicechannel, all of the System which is then in the Practice&rsquo;s possession, or
          under the Practice&rsquo;s control. </p>
      <h2>ARTICLE 3. COMPENSATION. </h2>
      <p>During the Term of this Agreement, the Practice will pay Practicechannel the user fee
          which is described on the attached &ldquo;Exhibit B&rdquo; (the
        &ldquo;User Fee&rdquo;). The User Fee will be paid to
          Practicechannel on a monthly basis no later than the fifth (5th) day of each calendar
          month which is encompassed by the Term of this Agreement, commencing as of the calendar
          month which immediately follows the Execution Date of this Agreement. </p>
      <h2>ARTICLE 4. CONFIDENTIALITY. </h2>
      <p>4.01 Confidential Information. The Parties, by the execution
          and delivery of this Agreement, expressly acknowledge that the Parties will have access to the Parties&rsquo;
          information, property, methods, material, files, records and/or data, including but not limited to data files,
          software, financial data, pricing, and patient information (collectively, the &ldquo;Confidential Information&rdquo;), all of which constitutes the valuable
          properties and assets of the Party which owns the Confidential Information. As a consequence, with the exception
          of disclosures which are specifically approved, in advance, by the Party owning the Confidential Information, each
          Party will not disclose, during the Term of this Agreement or subsequent to the termination of this Agreement, any
          of the other Party&rsquo;s Confidential Information, to any person or entity, other than the Party&rsquo;s
          employees, agents and contractors who are directly involved in the performance of the Party&rsquo;s duties and
          obligations under this Agreement, and who are also bound by a duty of confidentiality with respect to the
          Confidential Information. If either Party, or any employees or other representatives of either Party, are
          requested or required by law or by any legal process to disclose any of the Confidential Information, the
          disclosing Party will timely notify the Party owning the Confidential Information of the anticipated disclosure
          prior to actually disclosing that Confidential Information. Each Party further agrees to protect the other
          Party&rsquo;s Confidential Information in accordance with commercially reasonable standards which, at a minimum,
          require the same degree of care with which the Party protects its own Confidential Information. If either Party
          breaches any of its obligations with respect to confidentiality hereunder, the non-breaching Party shall be
          entitled to equitable relief to protect its interest therein, including but not limited to, injunctive relief, as
          well as money damages, notwithstanding anything to the contrary contained herein. The obligations of the Parties
          with respect to confidentiality shall survive the termination of this Agreement. </p>
      <p>4.02 HIPAA Compliance. Practicechannel will execute and deliver
          the Business Associate Agreement which is attached to this Agreement as &ldquo;Exhibit
          C,&rdquo; in order to evidence the Parties&rsquo; compliance with the &ldquo;Health Insurance Portability and Accountability Act of 1996,&rdquo; and all of
          the standards or rules which are or may be promulgated pursuant to that statute by the U.S. Department of Health
          and Human Services, to the extent that such standards or rules are applicable to Practicechannel&rsquo;s duties
          and obligations under this Agreement. </p>
      <h2>ARTICLE 5. WARRANTIES AND LIABILITY </h2>
      <p>5.01 Limited Warranty. Practicechannel represents and warrants that the software which is part of the System, when properly installed by
          the Practice and used in accordance with the instructions provided to the Practice by Practicechannel, and with
          the proper hardware, operating system, and internet access, will perform substantially as described in the
          Practicechannel Documentation described in Exhibit A. </p>
      <p>5.02 DISCLAIMER OF WARRANTIES. PRACTICECHANNEL EXPRESSLY DISCLAIMS ALL WARRANTIES
          AND REPRESENTATIONS WITH RESPECT TO THE SYSTEM, INCLUDING WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
          PARTICULAR USE, EXCEPT AS EXPRESSLY SET FORTH IN WRITING IN THIS AGREEMENT. </p>
      <p>5.03 LIMITATION OF LIABILITY. IN NO EVENT SHALL PRACTICECHANNEL BE LIABLE FOR
          LOSS-OF-PROFIT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES. THE PRACTICE SHALL BE DEEMED TO HAVE
          WAIVED ANY CLAIM NOT PRESENTED IN WRITING TO PRACTICECHANNEL WITHIN NINETY (90) DAYS AFTER THE OCCURRENCE OF THE
          EVENT GIVING RISE TO SUCH CLAIM. PRACTICECHANNEL&rsquo;S LIABILITY TO THE PRACTICE UNDER THIS AGREEMENT, OR
          RESULTING FROM THE RELATIONSHIP CREATED OR THE TRANSACTIONS CONDUCTED BETWEEN THE PARTIES BY VIRTUE OF THIS
          AGREEMENT, WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, SHALL IN NO EVENT EXCEED THE AGGREGATE, NON-REFUNDED
          AMOUNT PAID BY THE PRACTICE TO PRACTICECHANNEL, PURSUANT TO THIS AGREEMENT, DURING THE PRECEDING TWELVE (12)
          MONTHS. </p>
      <h2>ARTICLE 6. GENERAL PROVISIONS. </h2>
      <p>6.1 Amendments. No amendment of this Agreement will be valid or
          enforceable unless the amendment is reduced to writing and is then respectively executed and delivered by the
          Parties. </p>
      <p>6.2 Assignment. This Agreement is binding upon, and insures to
          the benefit of the Parties, their respective heirs and assigns, provided, however, that no assignment of this
          Agreement, or the license being granted to the Practice pursuant to this Agreement, will be valid without the
          prior written consent of Practicechannel. </p>
      <p>6.3 Governing Law and Venue. This Agreement will be construed,
          interpreted, enforced and governed, in all respects, by and under the law of the State of Illinois. All disputes
          between the Parties which arise out of this Agreement, or the relationship created between the Parties by virtue
          of this Agreement, shall be resolved by litigation in the courts of the State of Illinois, County of Sangamon,
          including the federal courts therein, and the Parties consent to the jurisdiction of such courts. </p>
      <p>6.4 Counterparts. This Agreement may be executed by the Parties
          in multiple original counterparts and transmitted by facsimile or emailed in *pdf format, and each executed
          version will then be deemed to be an original version of this Agreement, and all of the executed versions will be
          deemed to constitute the same document. </p>
      <p>6.5 Gender and Tense. Whenever the context of this Agreement
          requires, or a reasonable construction would warrant, the gender of all words utilized in this Agreement will be
          deemed to equally include the masculine, feminine and neuter genders, and the tense of all words will be deemed to
          include both the singular and the plural tenses. </p>
      <p>6.6 Legal Compliance. The Practice acknowledges that
          Practicechannel is licensing the System to Practice to design and operate the Practice&rsquo;s own Plan for the
          Practice&rsquo;s patients who are without insurance; that the Plan is not intended to be a form of dental
          insurance; that Practicechannel has made no representations, and the Practice is not relying upon Practicechannel
          for legal advice with respect to, the Plan&rsquo;s compliance with applicable law; and that the Practice has the
          responsibility to obtain its own legal counsel, if the Practice deems necessary, to determine the Plan&rsquo;s
          compliance with applicable law. </p>
      <p>6.7 Independent Contractor. The Practice is contracting with
          Practicechannel as an &ldquo;independent contractor,&rdquo; and none of the provisions of this Agreement are
          intended by the Parties to create a partnership, a joint venture or any other relationship between the Parties.
        </p>
      <p>6.8 Notices and Communications. Any notices or other
          communications which are required under or which are otherwise prescribed by this Agreement must be in writing and
          must either be (i) delivered to the Party entitled to the notice or communication, or (ii) mailed, with
          first-class postage prepaid, to the Party entitled to the notice or communication by registered or certified mail,
          return receipt requested, at the following addresses, or such other alternative addresses as the Parties may
          respectively specify, in writing, during the Term of this Agreement: </p>
      <p>Practicechannel: Attention: Joshua M. Renken<br>2801 Mansion Road Springfield, IL
          62711</p>
      <p>A notice or other communication will be deemed to be received in subparagraph (i), on
          the date of the actual receipt of the notice or communication by the Party entitled to the notice or
          communication, and in subparagraph (ii), on the date of the mailing of the notice or communication. </p>
      <p>6.9 Severability. The invalidity or unenforceability of any
          particular provision of this Agreement will not invalidate the remaining provisions of this Agreement and, in that
          event, this Agreement will be construed in all respects as if such invalid or unenforceable provision were omitted
          and deleted. </p>
      <p>6.10 Waiver of Breach. The waiver, by either of the Parties, of
          a breach of any provision of this Agreement will not operate or be construed as a waiver of any subsequent breach
          of this Agreement. </p>
      <p>6.11 Force Majeure. Practicechannel will not be deemed to be in default if its performance under this Agreement is delayed due to the
          occurrence of events which are beyond its reasonable control, such as acts of God, fire, natural disasters, acts
          of war or sabotage, labor disputes or raw material shortages, interruptions of power supply or communications,
          civil disturbances, or any similar event which causes Practicechannel&rsquo;s performance to be commercially
          impractical. </p>
      <p>6.12 Entire Agreement. This Agreement, including all exhibits
          and documents incorporated herein by reference, if any, constitutes the entire agreement between the Parties, and
          this Agreement supersedes any and all prior contracts or representations, whether oral or written, between the
          Parties relating to the subject matter of this Agreement. </p>
      <h2>EXHIBIT A THE SYSTEM </h2>
      <ul>
        <li>Unlimited access to the Practicechannel internet-based software platform
            &ldquo;Smiley&rdquo; for use in the Practice; </li>
        <li>Use of the name, &ldquo;Smiley,&rdquo; for the limited purpose of offering, and
            operating the Practice&rsquo;s plan; </li>
        <li>Initial training via online training guide; </li>
        <li>Reasonable support through email consultation; </li>
        <li>Email notification of updates to platform and online training for implementation of
            new features; and </li>
      </ul>
      <p>Practice agrees to maintain the structure of the System and Plan, but may independently
          define the care and prices offered within that structure. </p>
      <p>Practice understands and agrees that Practicechannel has the right to monitor
          Practice&rsquo;s use of the System, including but not limited to the software platform and the
          &ldquo;Practicechannel&rdquo; name, and that Practicechannel has the right, in its discretion and in addition to
          any other remedies available to it at law or in equity, terminate the Agreement or suspend Practice&rsquo;s
          license to use the System, if, in the reasonable opinion of Practicechannel, the Practice is using any part of the
          System in a manner that is in any way unlawful or detrimental to the &ldquo;Practicechannel&rdquo; brand, and such
          use continues for more than five (5) days after Practicechannel notifies the Practice, in writing. </p>
      <h2>EXHIBIT B USER FEE </h2>
      <p>The User Fee will be paid, according to the following schedule, based on the number of
          individual patients who are enrolled in the Practice&rsquo;s Plan as of the last day of the prior calendar month:
        </p>
      <p>Pricing is as follows: </p>
      <ul>
        <li>Practice Plan</li>
      </ul>
      <ul>
        <li>1 Group, 1 Location, 20 Team Members</li>
        <li>$49/mo</li>
      </ul>
      <ul>
        <li>Group Plan</li>
      </ul>
      <ul>
        <li>1 Group, Unlimited Locations &amp; Team Members</li>
        <li>$99/mo</li>
      </ul>
      <ul>
        <li>Network Plan</li>
      </ul>
      <ul>
        <li>Unlimited Groups, Locations, &amp; Team Members</li>
        <li>$299/mo </li>
      </ul>
      <p>Practicechannel may increase pricing, to be effective at the commencement of a new Term
          year under this Agreement, provided that it notifies Practice in writing at least 120 days prior to any such
          increase. </p>
      <p>Practicechannel has the right to access the Practice&rsquo;s Plan information for the
          purpose of confirming the accuracy of the User Fee paid by the Practice. </p>
      <p>In addition to any other remedies for default set forth in this Agreement, if the
          Practice defaults in the performance of its obligation to pay the User Fee, and fails to cure the default within
          five (5) days following receipt of notice of the default from Practicechannel, then Practicechannel may elect, in
          its sole discretion, to suspend the Practice&rsquo;s license to use the System. A SERVICE CHARGE OF 1.5% PER MONTH
          WILL BE MADE ON ALL PAST DUE BALANCES, WHICH IS AN ANNUAL RATE OF 18%. IN THE EVENT THAT PRACTICECHANNEL FILES A
          CAUSE OF ACTION TO COLLECT UNPAID USER FEES OR TO ENFORCE ANY OF THE PROVISIONS OF THIS AGREEMENT, OR FILES ANY
          OTHER ACTION ARISING OUT OF THE RELATIONSHIP CREATED BY VIRTUE OF THIS AGREEMENT, PRACTICECHANNEL, IF IT PREVAILS
          IN ANY SUCH ACTION, WILL BE ENTITLED TO RECOVER COURT COSTS AND REASONABLE ATTORNEYS&rsquo; FEES. </p>
      <h2>EXHIBIT C BUSINESS ASSOCIATE AGREEMENT </h2>
      <p>This Business Associate Agreement (&ldquo;Agreement&rdquo;) between the legal entity of
          your dental group or practice (the &ldquo;Covered Entity&rdquo;) and (ii) Practicechannel,
          LLC, an Illinois limited liability company (the &ldquo;Business Associate&rdquo;). The
          Covered Entity and the Business Associate are collectively referred to in this Agreement as the
          &ldquo;Parties,&rdquo; and either the Covered Entity or the Business Associate may be separately and individually
          identified in this Agreement as a &ldquo;Party.&rdquo; </p>
      <p>Recitals: </p>
      <p>WHEREAS, the &ldquo;Health Insurance Portability and Accountability Act of 1996&rdquo;
          (&ldquo;HIPAA&rdquo;) grants the Secretary authority to promulgate and publish rules regulating the privacy and
          the security of certain categories of patient health information; and </p>
      <p>WHEREAS, the &ldquo;Health Information Technology for Economic and Clinical Health
          Act&rdquo; (&ldquo;HITECH&rdquo;), Title XIII of Division A and Title IV of Division B of the American Recovery
          and Reinvestment Act of 2009, imposes additional requirements with respect to the privacy and security of
          Protected Health Information, and requires the Secretary to promulgate and publish additional rules regulating the
          privacy and security of Protected Health Information; and </p>
      <p>WHEREAS, the Secretary has promulgated and published the Privacy Rule, the Security
          Rule, and the Breach Notification Rule, all of which are designed to protect the privacy and security of
          Individually Identifiable Health Information maintained or transmitted in connection with certain administrative
          and financial transactions; and </p>
      <p>WHEREAS, HIPAA, HITECH, the Privacy Rule, the Security Rule, and the Breach
          Notification Rule all require covered entities to enter into a contract, referred to as a business associate
          agreement, with contractors receiving Protected Health Information, and mandate compliance with certain terms and
          conditions; and </p>
      <p>WHEREAS, the services which the Business Associate contemplates performing on behalf of
          the Covered Entity involve the use and/or disclosure of Protected Health Information, and, as a consequence, a
          business associate agreement between the Parties is required; </p>
      <p>NOW, THEREFORE, in consideration of the covenants, terms and conditions which are
          contained in this Agreement, the respective obligations of the Parties, as defined in this Agreement, and other
          good and valuable considerations, the receipt and sufficiency of such additional considerations being acknowledged
          by the Parties, the Covered Entity and the Business Associate agree as follows: </p>
      <h2>1. DEFINITIONS. </h2>
      <p>The following terms and phrases possess the meaning which is assigned to such terms and
          phrases by the provisions of this Agreement: </p>
      <p>1.1 Administrative Safeguards. &ldquo;Administrative Safeguards&rdquo; has the same
          meaning as the term &ldquo;administrative safeguards&rdquo; in 45 C.F.R. &sect;164.304. </p>
      <p>1.2 Availability. &ldquo;Availability&rdquo; has the same meaning as the term
          &ldquo;availability&rdquo; in 45 C.F.R. &sect;164.304. </p>
      <p>1.3 Breach. &ldquo;Breach&rdquo; has the same meaning as the term &ldquo;breach&rdquo;
          in 45 C.F.R. &sect;164.402. </p>
      <p>1.4 Breach Notification Rule. &ldquo;Breach Notification Rule&rdquo; means the
          &ldquo;Notification in the Case of Breach of Unsecured Protected Health Information&rdquo; rule, as published at
          45 C.F.R. part 164, subpart D. </p>
      <p>1.5 Confidentiality. &ldquo;Confidentiality&rdquo; has the same meaning as the term
          &ldquo;confidentiality&rdquo; in 45 C.F.R. &sect;164.304. </p>
      <p>1.6 Data Aggregation. &ldquo;Data Aggregation&rdquo; has the same meaning as the term
          &ldquo;data aggregation&rdquo; in 45 C.F.R. &sect;164.501. </p>
      <p>1.7 Designated Record Set. &ldquo;Designated Record Set&rdquo; has the same meaning as
          the term &ldquo;designated record set&rdquo; in 45 C.F.R. &sect;164.501. </p>
      <p>1.8 Individual. &ldquo;Individual&rdquo; has the same meaning as the term
          &ldquo;individual&rdquo; in 45 C.F.R. &sect;160.103, and includes a person who qualifies as a personal
          representative under 45 C.F.R. &sect;164.502(g). </p>
      <p>1.9 Individually Identifiable Health Information. &ldquo;Individually Identifiable
          Health Information&rdquo; has the same meaning as the term &ldquo;individually identifiable health
          information&rdquo; in 45 C.F.R. &sect;160.103. </p>
      <p>1.10 Integrity. &ldquo;Integrity&rdquo; has the same meaning as the term
          &ldquo;integrity&rdquo; in 45 C.F.R. &sect;164.304. </p>
      <p>1.11 Physical Safeguards. &ldquo;Physical Safeguards&rdquo; has the same meaning as the
          term &ldquo;physical safeguards&rdquo; in 45 C.F.R. &sect;164.304. </p>
      <p>1.12 Privacy Rule. &ldquo;Privacy Rule&rdquo; means the &ldquo;Standards for Privacy of
          Individually Identifiable Health Information,&rdquo; as published at 45 C.F.R. part 160 and part 164, subparts A
          and E. </p>
      <p>1.13 Protected Health Information. &ldquo;Protected Health Information&rdquo; has the
          same meaning as the term &ldquo;protected health information&rdquo; in 45 C.F.R. &sect;160.103, limited to the
          information created on behalf of a Covered Entity by a Business Associate, or received by a Business Associate
          from a Covered Entity. </p>
      <p>1.14 Required by Law. &ldquo;Required by Law&rdquo; has the same meaning as the term
          &ldquo;required by law&rdquo; in 45 C.F.R. &sect;164.103. </p>
      <p>1.15 Security Incident. &ldquo;Security Incident&rdquo; has the same meaning as the
          term &ldquo;security incident&rdquo; in 45 C.F.R. &sect;164.304. </p>
      <p>1.16 Security Rule. &ldquo;Security Rule&rdquo; means the &ldquo;Security Standards for
          the Protection of electronic Protected Health Information,&rdquo; as published at 45 C.F.R. part 160 and part 164,
          subpart C. </p>
      <p>1.17 Secretary. &ldquo;Secretary&rdquo; means the Secretary of the Department of Health
          and Human Services, or his or her designee. </p>
      <p>1.18 Technical Safeguards. &ldquo;Technical Safeguards&rdquo; has the same meaning as
          the term &ldquo;technical safeguards&rdquo; in 45 C.F.R. &sect;164.304. </p>
      <p>1.19 Unsecured Protected Health Information. &ldquo;Unsecured Protected Health
          Information&rdquo; has the same meaning as the term &ldquo;unsecured protected health information&rdquo; in 45
          C.F.R. &sect;164.402. </p>
      <p>1.20 Other Terms or Phrases. Other terms or phrases which are utilized in this
          Agreement, without a specific definition being attributable to such terms and phrases, will possess the same
          meaning which is respectively assigned to the terms or phrases in HIPAA or HITECH, or any regulation promulgated
          under HIPAA or HITECH, as is applicable under the circumstances. </p>
      <h2>2. PERMITTED USES AND DISCLOSURES. </h2>
      <p>2.1 Except as otherwise limited by or under this Agreement, the Business Associate may
          use or disclose Protected Health Information to perform the duties and obligations of the Business Associate under
          the one or more contracts which exist between the Parties on the Effective Date (the &ldquo;Contract&rdquo;), so
          long as that use or disclosure would not violate the Privacy Rule, or the applicable policies and procedures of
          the Covered Entity relating to such use or disclosure. </p>
      <p>2.2 The Business Associate acknowledges that, as between the Business Associate and the
          Covered Entity, all Protected Health Information shall be and remain the sole property of the Covered Entity,
          including any and all forms thereof developed by the Business Associate in the course of its fulfillment of its
          obligations pursuant to the underlying Contract. </p>
      <p>2.3 The Business Associate&rsquo;s requests for disclosure of Protected Health
          Information by Covered Entity to the Business Associate shall be only for the minimum necessary Protected Health
          Information for the accomplishment of the Business Associate&rsquo;s purpose. </p>
      <p>2.4 Except as otherwise limited by or under this Agreement, the Business Associate may
          use or disclose Protected Health Information in connection with the proper management and administration of the
          activities of the Business Associate, or to perform the duties and responsibilities of the Business Associate,
          including the duties and responsibilities created by the underlying Contract. </p>
      <p>2.5 Except as otherwise limited by or under this Agreement, the Business Associate may
          disclose Protected Health Information if such disclosures are Required By Law, or if the Business Associate
          obtains reasonable assurances from the person or entity receiving the Protected Health Information from the
          Business Associate that the Protected Health Information will remain confidential and will be used or further
          disclosed only as Required by Law, or in connection with the specific purpose which is occasioning the disclosure,
          and the person or entity notifies the Business Associate of any instances when the confidentiality of the
          Protected Health Information has been compromised. </p>
      <p>2.6 The Business Associate may use Protected Health Information to furnish Data
          Aggregation services, as permitted by 45 C.F.R. &sect;164.504(e)(2)(i)(B). </p>
      <p>2.7 The Business Associate may use Protected Health Information to report violations of
          law to appropriate federal and state authorities, consistent with 45 C.F.R. &sect;164.502(j)(1). </p>
      <h2>3. OBLIGATIONS OF THE BUSINESS ASSOCIATE. </h2>
      <p>3.1 The Business Associate will maintain the Confidentiality of, and will not use or
          disclose Protected Health Information, other than to the extent permitted by this Agreement or as Required By Law.
        </p>
      <p>3.2 The Business Associate will use appropriate safeguards to prevent the use or
          disclosure of Protected Health Information, except to the extent permitted under this Agreement. </p>
      <p>3.3 The Business Associate will timely act to mitigate, to the extent practicable, any
          harmful effect, which is known to or which could reasonably be anticipated by the Business Associate, of a use or
          a disclosure of Protected Health Information by the Business Associate in breach or violation of the provisions of
          this Agreement. </p>
      <p>3.4 The Business Associate will report to the Covered Entity any use or disclosure of
          Protected Health Information in violation of this Agreement by the Business Associate, its officers, directors,
          employees, contractors or agents, or by a third party to which the Business Associate disclosed Protected Health
          Information in compliance with this Agreement. </p>
      <p>3.5 The Business Associate will ensure that any agent, including a subcontractor, which
          receives Protected Health Information from or through the Business Associate, has agreed, prior to receiving that
          Protected Health Information, to the same restrictions and conditions as are applicable, under this Agreement, to
          the Business Associate. </p>
      <p>3.6 The Business Associate agrees that the internal practices, books and records,
          including policies and procedures and Protected Health Information, relating to the use and the disclosure of
          Protected Health Information which the Business Associate created on behalf of the Covered Entity or received from
          the Covered Entity will be available to the Secretary to assist the Secretary in determining whether the Covered
          Entity has adequately complied with the Privacy Rule. </p>
      <p>3.7 The Business Associate will document such disclosures of Protected Health
          Information, and information related to such disclosures, as would be required to enable the Covered Entity to
          respond to a request by an Individual that the Covered Entity furnish, to the Individual, an accounting of
          disclosures of Protected Health Information, in compliance with 45 C.F.R. &sect;164.528. </p>
      <p>3.8 The Business Associate will furnish the Covered Entity, or an Individual,
          information collected in accordance with Section 3.7 of this Agreement, to permit the Covered Entity to respond to
          a request by an Individual that the Covered Entity furnish to the Individual an accounting of disclosures of
          Protected Health Information, in compliance with 45 C.F.R. &sect;164.528. </p>
      <p>3.9 The Business Associate will implement Administrative Safeguards, Physical
          Safeguards and Technical Safeguards which reasonably and appropriately protect the Confidentiality, Integrity and
          Availability of the electronic Protected Health Information which the Business Associate creates on behalf of the
          Covered Entity, or receives from the Covered Entity or otherwise maintains or transmits on behalf of the Covered
          Entity, as required by the Security Rule. </p>
      <p>3.10 The Business Associate will ensure that any agent, including a subcontractor,
          which receives electronic Protected Health Information from or through the Business Associate has agreed, prior to
          receiving such electronic Protected Health Information, to implement reasonable and appropriate Administrative
          Safeguards, Physical Safeguards and Technical Safeguards to protect the Confidentiality, Integrity and the
          Availability of such information, as required by the Security Rule. </p>
      <p>3.11 The Business Associate will report to the Covered Entity the occurrence of any
          Security Incident whenever the Business Associate becomes aware of the occurrence of that Security Incident.
        </p>
      <p>3.12 The Business Associate agrees to promptly notify the Covered Entity of the
          Business Associate&rsquo;s receipt of any request or subpoena for Protected Health Information or a request for an
          accounting of disclosures of Protected Health Information, and to forward any such request or subpoena to the
          Covered Entity. To the extent that the Covered Entity decides to assume responsibility for challenging the
          validity of any request or subpoena, the Business Associate shall cooperate with any reasonable requests by the
          Covered Entity in connection with such challenge, which, in any event, shall not require the Business Associate to
          expend its funds or incur of any liability. </p>
      <p>3.13 The Business Associate shall make available to the Covered Entity such Protected
          Health Information for so long as such information is maintained in the Designated Record Set. In the event any
          Individual requests access to Protected Health Information directly from the Business Associate, the Business
          Associate shall forward such request to the Covered Entity. Any denials of access to the Protected Health
          Information requested shall be the responsibility of the Covered Entity. </p>
      <p>3.14 Upon request from the Covered Entity for the amendment of an Individual&rsquo;s
          Protected Health Information or a record regarding an Individual contained in a Designated Record Set (for so long
          as the Protected Health Information is maintained in the Designated Record Set), the Business Associate shall
          provide such information to the Covered Entity for amendment and incorporate any such amendments in the Protected
          Health Information as required by 45 C.F.R. &sect;164.526. </p>
      <p>3.15 The Business Associate will comply with the applicable provisions of the Breach
          Notification Rule, except to the extent that this Agreement contains more stringent requirements that are not
          inconsistent with the Breach Notification Rule, in which case this Agreement will govern. The Business Associate
          will notify the Covered Entity following the discovery of a Breach of Unsecured Protected Health Information. The
          Business Associate will provide written notification, including identification of the individual(s) affected by
          the Breach, as soon as possible and without unreasonable delay following discovery of the Breach, but in no case
          will the Business Associate provide the notification later than sixty (60) days following discovery of the Breach.
        </p>
      <p>3.16 The Business Associate will comply with Sections 164.308, 164.310, 164.312, and
          164.316 of the Security Rule, as well as the additional requirements contained in HITECH which relate to security
          and are made applicable to the Business Associate. </p>
      <p>3.17 The Business Associate will only use and disclose PHI in compliance with each
          applicable requirement of Section 164.504(e) of the Privacy Rule, and will comply with the additional requirements
          contained in HITECH which relate to privacy and are made applicable to the Business Associate. </p>
      <h2>4. OBLIGATIONS OF THE COVERED ENTITY. </h2>
      <p>4.1 The Covered Entity will notify the Business Associate of any limitation(s) in the
          Notice of Privacy Practices of the Covered Entity, in compliance with 45 C.F.R. &sect;164.520, to the extent that
          such limitation(s) may adversely affect the Business Associate&rsquo;s use or disclosure of Protected Health
          Information. </p>
      <p>4.2 The Covered Entity will notify the Business Associate of any changes in, or a
          revocation of the previously granted permission by an Individual to use or disclose Protected Health Information,
          to the extent that such changes may adversely affect the Business Associate&rsquo;s use or disclosure of Protected
          Health Information. </p>
      <p>4.3 The Covered Entity will inform the Business Associate of any confidential addresses
          or any other accommodation granted by the Covered Entity, in response to an Individual&rsquo;s requested
          confidential communications in compliance with 45 C.F.R. &sect;164.522, to the extent that such restrictions may
          adversely affect the Business Associate&rsquo;s use or disclosure of Protected Health Information.. </p>
      <p>4.4 The Covered Entity will not request that the Business Associate use or disclosure
          Protected Health Information in any manner which would not be permitted by or under the Privacy Rule. </p>
      <h2>5. TERM AND TERMINATION. </h2>
      <p>5.1 The Term of this Agreement commences on the Effective Date, and will subsequently
          continue in force so long as the underlying Contract between the Parties remains in force. </p>
      <p>5.2 In the event of a material breach or violation of this Agreement by either Party,
          the non-breaching Party will either: </p>
      <p>5.2.1 allow the other Party an opportunity to cure the breach or violation within the
          ensuing ten (10) day period which follows the occurrence of the breach or violation, if reasonable under the
          circumstances, unless an alternative cure date is mutually established by the Parties; or </p>
      <p>5.2.2 immediately terminate this Agreement and the underlying Contract if the Party has
          breached or violated a material term or provision of this Agreement and a reasonable cure of the breach or
          violation is not feasible; or </p>
      <p>5.2.3. report the breach or violation to the Secretary, if neither the termination of
          this Agreement and the underlying Contract nor a cure of the breach or violation is feasible. </p>
      <p>5.3. If this Agreement and the underlying Contract are terminated by either of the
          Parties, the Business Associate will promptly return to the Covered Entity all of the Protected Health Information
          previously received from, or created or received by the Business Associate on behalf of the Covered Entity, which
          the Business Associate, or any agent or subcontractor of the Business Associate, then maintains in any form, and
          the Business Associate will retain no copies of any of that Protected Health Information; however, if the return
          of the Protected Health Information is not feasible, in the mutual opinion of the Parties, the Business Associate
          will continue to extend the protections of this Agreement to the Protected Health Information, and the Business
          Associate, by the execution and delivery of this Agreement, represents and warrants that the Business Associate
          will limit further uses and disclosures to the purposes which have caused the return of the Protected Health
          Information to be impractical. </p>
      <h2>6. AGENCY. </h2>
      <p>The Parties are independent contractors, and no agency, partnership, joint venture or
          employment relationship is intended or created by this Agreement. </p>
      <h2>7. MISCELLANEOUS PROVISIONS. </h2>
      <p>7.1 Regulatory References. A reference in this Agreement to a section in the Privacy
          Rule, the Security Rule, or the Breach Notification Rule means the section as in effect on the Effective Date, or
          as subsequently amended. </p>
      <p>7.2 Amendment. The Parties agree to act as may be necessary subsequent to the Effective
          Date to amend this Agreement to comply with any requirements related to the Privacy Rule, the Security Rule, the
          Breach Notification Rule, and any other regulations or provisions of HIPAA or HITECH which are adopted,
          promulgated or published after the Effective Date.  </p>
      <p>7.3 HIPAA/HITECH Compliance. The Parties expect the Secretary to promulgate and publish
          additional rules in the future under the authority granted by HIPAA and/or HITECH. Business Associate will comply
          with the applicable provisions of such future rules upon their respective effective dates, and this Agreement
          hereby incorporates the requirements contained in those provisions without the need for further amendment of this
          Agreement. </p>
      <p>7.4 Survival. The duties and obligations imposed on the Business Associate by the terms
          and provisions of Section 5.3 of this Agreement will survive, in all events, the termination of this Agreement,
          and/or the termination of the underlying Contract. </p>
      <p>7.5 Interpretation. The terms and provisions of this Agreement shall supersede any
          other conflicting or inconsistent terms and provisions in the underlying Contract, including all exhibits or other
          attachments thereto and all documents incorporated therein by reference. Any ambiguity in this Agreement will be
          resolved so as to permit the Covered Entity to comply with HIPAA and HITECH and any regulation promulgated under
          with HIPAA or HITECH. </p>
      <p>7.6 Governing Law. This Agreement will be interpreted, construed and governed by and
          under the law of the State of Illinois. </p>
      <p>7.7 Injunctive Relief. The Business Associate acknowledges and agrees that the Covered
          Entity will suffer irreparable damage upon the Business Associate&rsquo;s breach of any material term of this
          Agreement and that such damages shall be difficult to quantify. The Business Associate acknowledges and agrees
          that, in such event, the Covered Entity may file an action for an injunction to enforce the terms of this
          Agreement against the Business Associate, in addition to any other remedy the Covered Entity may have. </p>
      <p>7.8 Severability. In the event that any term or provision of this Agreement is
          determined by a court of competent jurisdiction to be invalid or unenforceable, the remaining terms and provisions
          of this Agreement will remain in full force and effect. In addition, in the event that the Covered Entity
          determines, in good faith, that any term or provision of this Agreement fails to comply with HIPAA or HITECH, or
          any of the regulations promulgated pursuant to HIPAA or HITECH, the Covered Entity will notify the Business
          Associate, in writing. During the ensuing thirty (30) day period following the receipt of that notification, the
          Parties will address such concern in good faith and will endeavor to amend the terms and provisions of this
          Agreement so the Agreement is compliant. If, after that thirty (30) day period has expired, this Agreement
          continues to be noncompliant, in the Covered Entity&rsquo;s opinion, then this Agreement may be terminated by the
          Covered Entity pursuant to Section 5.2 of this Agreement. </p>
      <p>7.9 Entire Agreement. This document, along with the underlying Contract, including all
          exhibits or other attachments thereto and all documents incorporated therein by reference, constitutes the entire
          agreement between the Parties, and this Agreement supersedes any and all prior contracts, whether oral or written,
          between the Parties relating to the subject matter of this Agreement. </p>
      <p>7.10 Captions. The captions which are utilized in this Agreement serve as a description
          of the paragraph contents only, and such captions are not intended to be interpretations of this Agreement.
        </p>
      <p>7.11 Multiple Counterparts. This Agreement may be executed by the Parties in multiple
          original counterparts and transmitted by facsimile or emailed in *pdf format, and each executed version will then
          be deemed to be an original version of this Agreement, and all of the executed versions will be deemed to
          constitute the same document. </p>


    </div>
  </layout-public-wide>
</template>

<script>
import {mapGetters} from 'vuex'
import LayoutPublicWide from '../components/LayoutPublicWide.vue'
import ListErrors from '../components/ListErrors.vue'

export default {
  data () {
    return {
      
    }
  },
  components: {
    LayoutPublicWide, ListErrors
  },
  computed: {
  },
  watch: {
  },
}
</script>

<style lang="less" scoped>
</style>
