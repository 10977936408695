<template lang="html">
  <button class="btn btn-link btn-copy" @click="copyText()">
    <i class="far fa-copy"></i><em class="sm" :style="{visibility: clicked ? 'visible' : 'hidden'}">copied!</em>
  </button>
</template>

<script>
export default {
  props: ['clipboardText'],
  data () {
    return {
      clicked: false,
    }
  },
  methods: {
    copyText() {
      // copy to clipboard
      navigator.clipboard.writeText(this.clipboardText)
      .then(() => {
        // console.log('Text copied to clipboard');
        // set em inside target btn as visible
        this.clicked = true;
      })
    },
  },
}
</script>

<style lang="less" scoped>
.btn-copy {
  margin-left: 5px;
  vertical-align: top; // fix vertical alignment with p in table
  text-decoration: none;
}
</style>
