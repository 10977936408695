<template lang="html">
  <div>
    <div v-if="!noStatsFound">
      <p class="subtitle"><strong>Patient Health</strong></p>
      <h3>Provider Care Progress Rate</h3>
      <p>Percentage of patients with unmet care who have an appointment scheduled.</p>
    </div>

    <span v-if="!providerUid || !providerUser"><i class="far fa-fw fa-pulse fa-spinner"></i></span>
    
    <p v-if="noStatsFound"><em>no data found for this provider code</em></p>

    <div v-if="!noStatsFound" class="chart-key d-flex justify-content-between align-items-end">
      <div>
         <p v-if="activeRangeTitle" class="snug"><strong>{{ activeRangeTitle }}</strong></p>
         <div v-if="activeRangeTitle && rangePercAvg && rangePercAvgTitle">
            <span
              class="color-swatch"
              :class="'color-swatch-' + decideBarColorClass(rangePercAvg)"
            ></span>
            {{ rangePercAvg }}% &mdash; 
            {{ rangePercAvgTitle }}
         </div>
      </div>
      <div>
         <p class="snug">
          <button
            v-for="(opt,index) in rangeOptions"
            :key="index"
            class="btn btn-link btn-range"
            @click="activeRange=opt"
            :class="{'active': activeRange==opt}"
          >
            {{ opt }}
          </button>
         </p>
      </div>
    </div>

    <div v-if="providerUser && statValues && statValues.length && activeRange" class="chart chart-bars chart-care-progress">
      <div class="bars-container d-flex flex-row">
        <div class="labels-y">
          <p><span>100%</span>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><span>50%</span>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
        <!-- use flex reverse to show items in correct order since they were loaded in desc but need to show in asc -->
        <div class="flex-fill d-flex flex-row flex-row-reverse justify-content-end">
          <div
            v-for="(statVal,i) in statValues"
            class="bar-container d-flex flex-column justify-content-end align-items-center"
            :style="{width: (100/statValues.length) + '%'}"
            @click="toggleActiveBar(i)"
            :class="[
              {active: activeBar==i},
              decideBarColorClass(statVal.percentage),
              {narrow: statValues.length > 14}
            ]"
          >
            <p v-if="statVal.percentage!=='error'" class="label" :style="{bottom: statVal.percentage + '%'}">{{ statVal.percentage }}%</p>
            <div v-if="statVal.percentage!=='error'" class="bar" :style="{height: statVal.percentage + '%'}"></div>
            <div v-if="statVal.percentage=='error'" class="bar bar-error"><small>no data</small></div>
          </div>
        </div>
        
      </div> <!-- /.bars-container -->
      
      <div class="labels-x d-flex flex-row flex-row-reverse justify-content-between">
        <div
          v-for="(statVal,i) in statValues"
          class="bar-label d-flex flex-column justify-content-end align-items-center text-center"
          :style="{width: (100/statValues.length) + '%'}"
        >
          <p v-if="activeRange=='1w'" class="label-badge-value">{{ statVal.timestamp | prettyDateDayMo }}</p>
          <p v-if="activeRange=='28d'" v-show="statValues.length<=14 || i % 7 == 0" class="label-badge-value">{{ statVal.timestamp | prettyDateDayMo }}</p>
          <p v-if="activeRange=='3mo'" class="label-badge-value" v-show="activeBar==i">
            {{ findMomentWkStart(statVal.timestamp) | prettyDateDayMo }} - {{ statVal.timestamp | prettyDateDayMo }}
          </p>
          <p v-if="activeRange=='1y'" class="label-badge-value">{{ statVal.timestamp | prettyDateMoName }}</p>
        </div>
      </div> <!-- /.labels-x -->
      <p class="w-100 snug text-center color-dim sm" v-if="!activeBar && activeRange=='3mo'">select a bar to see dates</p>
    </div> <!-- /.chart -->

    

  </div> <!-- /.col -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import moment from 'moment'

export default {
  props: ['providerUid','providerUser'],
  data () {
    return {
      statValues: [],
      activeBar: null,
      rangeOptions: ['1w','28d','3mo','1y'],
      activeRange: null,
      noStatsFound: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    activeRangeTitle() {
      switch (this.activeRange) {
        case '1w':
          return '1-Week Average';
          break;
        case '28d':
          return '30-Day Average';
          break;
        case '3mo':
          return '3-Month Average';
          break;
        case '1y':
          return '1-Year Average';
          break;
        default:
          return null;
          break;
      }
    },
    rangePercAvg() {
      if(this.statValues && this.statValues.length) {
        // get sum of all non-error values
        const tot = this.statValues.reduce((partialSum,next) => {
          if(next.percentage !== 'error') {
            // console.log(Number(next.percentage).toFixed(2))
            return (Number(partialSum) + Number(next.percentage)).toFixed(2);
          }
          return partialSum;
        },0);
        // get count of non-error & non-zero values
        const count = this.statValues.filter(st => st.percentage !== 'error' && st.percentage > 0).length;
        // guard against dividing by zero
        if(!count || count <1) {
          return 0;
        }
        return (tot / count).toFixed(2);
      }
      return null;
    },
    rangePercAvgTitle() {
      if(!this.rangePercAvg) {
        return null;
      }
      if(this.rangePercAvg <= 30) {
        // return 'red';
        return 'Living the Way';
      }
      if(this.rangePercAvg <= 40) {
        // return 'orange';
        return 'Delivering the Way';
      }
      if(this.rangePercAvg <= 50) {
        // return 'yellow';
        return 'Growing the Way';
      }
      if(this.rangePercAvg <= 60) {
        // return 'blue';
        return 'Leading the Way';
      }
      if(this.rangePercAvg > 60) {
        // return 'green';
        return 'Teaching the Way';
      }
    },
  },
  watch: {
    providerUid() {
      if(this.providerUid && this.providerUser) {
        this.loadPatientStats();
      }
    },
    activeRange() {
      if(this.activeRange) {
        // reset active bar
        this.activeBar = null;
        // build a query based on range
        switch (this.activeRange) {
          case '1w':
            // load 1w of daily data
            this.loadProvCareProgress({
              path: `customers/${this.custom_claims.customerId}/stats/prov_care_progress/${this.providerUid}`,
              limit: 7,
            });
            break;
          case '28d':
            // load 30 points of daily averages
            this.loadProvCareProgress({
              path: `customers/${this.custom_claims.customerId}/stats/prov_care_progress/${this.providerUid}`,
              limit: 28,
            });
            break;
          case '3mo':
            // load 12 points of weekly averages
            this.loadProvCareProgress({
              path: `customers/${this.custom_claims.customerId}/stats/prov_care_progress/${this.providerUid}/averages/weekly`,
              limit: 12,
            });
            break;
          case '1y':
            // load 12 points of monthly averages
            this.loadProvCareProgress({
              path: `customers/${this.custom_claims.customerId}/stats/prov_care_progress/${this.providerUid}/averages/monthly`,
              limit: 12,
            });
            break;
          default:
            return null;
            break;
        }
      }
    }
  },
  methods: {
    toggleActiveBar(barIndex) {
      if(this.activeBar == barIndex) {
        this.activeBar = null;
      } else {
        this.activeBar = barIndex;
      }
    },
    findMomentWkStart(timestamp) {
      if(timestamp) {
        return moment.unix(timestamp).subtract(6,'days').unix();
      }
      return null;
    },
    decideBarColorClass(percentage) {
      if(percentage == "error") {
        return '';
      }
      if(percentage <= 30) {
        return 'red';
      }
      if(percentage <= 40) {
        return 'orange';
      }
      if(percentage <= 50) {
        return 'yellow';
      }
      if(percentage <= 60) {
        return 'blue';
      }
      if(percentage > 60) {
        return 'green';
      }
    },
    loadProvCareProgress(firestoreOptions) {
      // example object
      // let firestoreOptions = {
      //   path = `customers/${this.custom_claims.customerId}/stats/prov_care_progress/${this.providerUid}`,
      //   limit: 7,
      //   startTimestamp: 123456,
      //   endTimestamp: 123456,
      // };
      if(!this.custom_claims || !this.custom_claims.customerId || !firestoreOptions || !firestoreOptions.path) {
        console.error('loadProvCareProgress missing required params');
        return;
      }
      this.statValues = [];
      this.noStatsFound = false;

      // let statsRef;
      // build ref depending on firestoreOptions supplied

      // option 1: get everything between timestamps
        // used for 1w
      // if(firestoreOptions.startTimestamp && firestoreOptions.endTimestamp) {
      //   statsRef = Vue.firestore.collection(firestorePath)
      //     .where('timestamp','>=',startTimestamp)
      //     .where('timestamp','<=',endTimestamp)
      //     .orderBy('timestamp','desc');
      // }
      // option 2: get everything older than endTimestamp w/ limit
        // used for 1w 1y 14@monthly, 3mo 12@weekly, 28d 30@daily
      // if(!firestoreOptions.startTimestamp && firestoreOptions.endTimestamp && firestoreOptions.limit) {
      //   statsRef = Vue.firestore.collection(firestorePath)
      //     .where('timestamp','<=',endTimestamp)
      //     .limit(firestoreOptions.limit)
      //     .orderBy('timestamp','desc');
      // }

      // exit if no ref set
      // if(!statsRef) {
      //   console.error('incorrect firestoreOptions in loadProvCareProgress produced no statsRef');
      //   return;
      // }

      // get everything older than now
      const endTimestamp = moment().unix();
      
      Vue.firestore.collection(firestoreOptions.path)
        .where('timestamp','<=',endTimestamp)
        .limit(firestoreOptions.limit)
        .orderBy('timestamp','desc')
        .get()
      // statsRef.get()
        .then(statSnaps => {
          // console.log('statSnaps.docs',statSnaps.docs)
          if(statSnaps.empty) {
            console.log("error: empty query")
            this.noStatsFound = true;
            return;
          }
          statSnaps.docs.forEach(stat => {
            let dailyStat = stat.data();
            // console.log({dailyStat})
            let percentage = stat.data().percentage;
            if(percentage !== 'error') {
              percentage = (percentage).toFixed(1);
            }
            // store full data point instead of just percentages
            this.statValues.push({
              ...dailyStat,
              // overwrite w/ rounded to 2 places
              percentage: percentage,
            });
          })
        })
        .catch(err => console.log(err));
    },
  },
  mounted () {
    this.activeRange = this.rangeOptions[0];
    // TESTING ONLY
      // create testing data for last 30 days
      // must enable security rule first:
      // match /stats/prov_care_progress/{uid}/{id}
    // daily
    // for(let i=0; i<=30; i++) {
    //   const datestampLoop = moment().subtract(i,'days').format("YYYYMMDD");
    //   Vue.firestore.doc(`customers/${this.custom_claims.customerId}/stats/prov_care_progress/4IX403Hp95ZyARy69wZlg237Ir13/${datestampLoop}`)
    //     .set({
    //       datestamp: datestampLoop,
    //       locationId: 'pIKSIs9Pum9dCEP5SCBX',
    //       percentage: i*3,
    //       provider_is_hyg: false,
    //       timestamp: moment().subtract(i,'days').hour(23).unix(),
    //     })
    //     .catch(err => console.log(err));
    // }
    // Weekly
    // for(let i=0; i<=11; i++) {
    //   const datestampLoop = moment().subtract(i,'weeks').format("YYYYMMDD");
    //   Vue.firestore.doc(`customers/${this.custom_claims.customerId}/stats/prov_care_progress/4IX403Hp95ZyARy69wZlg237Ir13/averages/weekly/${datestampLoop}`)
    //     .set({
    //       datestamp: datestampLoop,
    //       locationId: 'pIKSIs9Pum9dCEP5SCBX',
    //       percentage: i*8,
    //       provider_is_hyg: false,
    //       timestamp: moment().subtract(i,'weeks').hour(23).unix(),
    //     })
    //     .catch(err => console.log(err));
    // }
    // yearly
    // for(let i=0; i<=11; i++) {
    //   const datestampLoop = moment().subtract(i,'months').format("YYYYMMDD");
    //   Vue.firestore.doc(`customers/${this.custom_claims.customerId}/stats/prov_care_progress/4IX403Hp95ZyARy69wZlg237Ir13/averages/monthly/${datestampLoop}`)
    //     .set({
    //       datestamp: datestampLoop,
    //       locationId: 'pIKSIs9Pum9dCEP5SCBX',
    //       percentage: 100-(i*8),
    //       provider_is_hyg: false,
    //       timestamp: moment().subtract(i,'months').hour(23).unix(),
    //     })
    //     .catch(err => console.log(err));
    // }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.hr-chart {
  border-top: 3px solid @dark-color;
  margin: 50px 0 30px;
}

.subtitle {
  margin: 0 0 5px;
}
h3 {
  margin: 0 0 10px;
}
.chart-care-progress {
  padding-top: 15px;

  .bars-container,
  .bar-container,
  .labels-y {
    height: 190px;
  }
  .bars-container {
    border-top: none; // override
  }
  .labels-x {
    padding-left: 45px; // account for labels-y

    p {
      border: none;
      padding: 0;
      margin: 5px 0 0;
      line-height: 1em;
      white-space: nowrap;
    }
    .bar-label {
      padding: 0 5px;
    }
  }
  .bar-container {
    width: 14.28%;
    text-align: center;
  }
  .bar {
    width: 20px;
    margin: 0 auto; // center horizontally
  }
  .bar-container.narrow .bar {
    width: 10px;
  }
  .red .bar {
    background-color: @color-salmon;
  }
  .orange .bar {
    background-color: @color-orange;
  }
  .yellow .bar {
    background-color: @color-yellow;
  }
  .blue .bar {
    background-color: @color-blue;
  }
  .green .bar {
    background-color: @color-green;
  }
  .bar-error {
    height: 100%;
    border-color: @dim-color;
    border-style: dashed;
    position: relative;
    color: @dim-color;

    > small {
      transform: rotate(270deg);
      display: block;
      line-height: 1em;
      width: 50px;
      position: absolute;
      bottom: 1.75em;
      left: -1.25em;
    }
    // &:hover {
    //   background-color: @dim-color;
    //   color: @light-color;
    // }
  }
  .bar-container .label {
    width: auto; // re-center even if overflows width
    z-index: 100;
  }
  .bar-container.active {
    cursor: pointer;

    .label {
      visibility: visible;
      opacity: 1;
    }
    .bar {
      background-color: @dark-color;
    }
  }
}
.btn-range + .btn-range {
  border-left: 1px solid @dark-color !important;
  margin-left: 1em;
  padding-left: 1em;
}
.btn-range.active {
  text-decoration: none !important;
  font-weight: bold;
  cursor: auto;
}
// new styles
.chart-key {
  margin-bottom: 30px;
}
.color-swatch {
  border: 1px solid @dark-color;
  margin-right: 5px;
}
</style>
