<template lang="html">
  <div class="icon-picker">
    <button @click="showTooltip=!showTooltip" class="btn-icon-display">
      <i v-if="activeIcon" class="fas icon-lg" :class="activeIcon"></i>
      <span v-if="!activeIcon" class="icon-none"></span>
    </button>
    <div v-if="showTooltip" class="select-tooltip">
      <button @click="setIcon(icon)" v-for="icon in icons" class="btn-icon-select"><i class="fas fa-fw" :class="icon"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['activeIcon'],
  data() {
    return {
      selectedIcon: null,
      showTooltip: false,
      icons: [ 'fa-shoe-prints', 'fa-hand-heart', 'fa-tooth', 'fa-hand-holding-seedling', 'fa-users', 'fa-graduation-cap', 'fa-teeth', 'fa-toothbrush', 'fa-teeth-open', 'fa-grimace', 'fa-hands-heart', 'fa-hand-sparkles', 'fa-hand-holding-medical', 'fa-hand-holding-heart', 'fa-handshake-alt', 'fa-thumbs-up', 'fa-heart', 'fa-heartbeat', 'fa-medkit', 'fa-stethoscope', 'fa-user-md', 'fa-user-crown', 'fa-user-nurse', 'fa-user-graduate', 'fa-user-headset', 'fa-user-cowboy', 'fa-snowflakes', 'fa-sun', 'fa-raindrops', 'fa-moon-stars', 'fa-moon', 'fa-bolt', 'fa-cloud-rainbow', 'fa-sparkles', 'fa-stars', 'fa-pennant', 'fa-trophy', 'fa-trophy-alt', 'fa-medal', 'fa-award', 'fa-chart-line', 'fa-user-chart', 'fa-badge-check', 'fa-laugh-beam', 'fa-grin-stars', 'fa-grin-hearts', 'fa-grin-alt', 'fa-smile-wink',
      ],
    }
  },
  methods: {
    setIcon(icon) {
      this.selectedIcon = icon;
      this.showTooltip = false;
      this.$emit('set-icon',icon)
    },
  },
  mounted () {
    if(this.activeIcon) {
      this.selectedIcon = this.activeIcon;
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.icon-picker {
  position: relative;
}
.btn-icon-display {
  display: inline-block;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 0;
	font-size: 16px;
	line-height: 1em;
	padding: 0;
  background-color: @light-color;
	text-decoration: none;
	box-shadow: none !important;
  outline: none;
  border: 1px solid @dark-color;
  text-align: center;
  color: @dark-color;
  
  &:hover {
    color: @color-blue;
  }
}
.icon-lg {
  font-size: 20px;
  line-height: 50px;
}
.icon-none {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  overflow: hidden;
  
  &:before,
  &:after {
    content: '';
    display: block;
    width: 70px;
    height: 1px;
    border-top: 1px solid @error-color;
    position: absolute;
    top: 24px;
    left: -11px;
    z-index: 1;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(315deg);
  }
}
.select-tooltip {
  position: absolute;
  top: 50px;
  left: 0;
  border: 1px solid @dark-color;
  height: 102px;
  width: 252px;
  overflow-y: scroll;
  z-index: 1000;
  padding: 5px;
  background-color: @light-color;
}
.btn-icon-select {
  display: inline-block;
  // width: 50px;
  // height: 50px;
  // min-width: 50px;
  // min-height: 50px;
  border-radius: 0;
	font-size: 20px;
	line-height: 1em;
	padding: 10px;
  background-color: @light-color;
	text-decoration: none;
	box-shadow: none !important;
  outline: none;
  border: none;
  text-align: center;
  color: @dark-color;

  &:hover {
    background-color: @color-blue;
  }
}
</style>
