<template>
  <div id="app">
    <router-view :customer="customer" />
  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import store from './store'

export default {
  data () {
    return {
      customer: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
  },
  watch: {
    user_props(newProps, oldProps) {
      if(newProps && this.user_props.refreshTime > this.user.metadata.lastLoginAt) {
        // if refreshTime when user_props changed, get the token and update in state
        this.getCustomClaims();
      }
    },
    custom_claims() {
      // console.log('claims loaded')
      this.getCustomer();
      // if custom claims changed to hidden status, force log out
      if(this.custom_claims && this.custom_claims.hidden) {
        this.$firebase.logOut();
      }
    },
  },
  methods: {
    async getCustomClaims() {
      const token = await Vue.auth.currentUser.getIdToken(true);
      const decodedToken = await Vue.auth.currentUser.getIdTokenResult();
      store.dispatch('updateCustomClaims', decodedToken.claims);
      // console.log('auth token refreshed', decodedToken);
    },
    getCustomer() {
      // TODO: if has admin custom claims, get the customerId for that
        // instead of using the user id

      // exit if no custom_claims
      // console.log(this.custom_claims)
      if(!this.custom_claims) {
        // console.log('exit: no custom claims loaded');
        return;
      }
      let customerId;
      // account owner: if uid matches claims customerId, use that customerId
      if(this.custom_claims.customerId && this.custom_claims.customerId == this.user.uid) {
        customerId = this.custom_claims.customerId;
      }
      // delegated super user: if custom claims is a super admin, use that customerId
      else if(this.custom_claims.adminLevel == "superAdmin" && this.custom_claims.customerId) {
        customerId = this.custom_claims.customerId;
      }
      // // else, exit, don't load customer
      else {
        // console.log('exit: no permission to load customer');
        return;
      }
      // console.log({customerId});
      Vue.firestore.collection('customers')
        .doc(customerId)
        .onSnapshot((doc) => {
          if(doc.exists) {
            this.customer = {
              ...doc.data(),
              id: doc.id,
            };
            // console.log("LayoutPrivate Customer", this.customer);
          } else {
            this.customer = false;
            console.log('customer document not found')
          }
        });
    },
  },
  async mounted () {
    if(this.user) {
      // get user's custom claims
      this.getCustomClaims();
      // get user's customer object
      // this.getCustomer(); // not here, only get after custom_claims is set
    }
  },
}
</script>

<style lang="less">
@import "assets/less/style.less";
</style>
