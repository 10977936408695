<template lang="html">
  <div class="layout-private">

    <div class="header-private">
      <div class="container">
        <div class="row row-header">
          <div class="col-4">
            <router-link to="/" class="logo">
  						<img src="../assets/img/logo-smiley.svg" alt="smiley" width="94px" height="30px">
  					</router-link>
          </div> <!-- /.col -->
          <div class="col-8 text-right">
            <p class="d-lg-none snug"><button @click="toggleNav" class="btn btn-nav-toggle btn-dark"><i class="far fa-bars"></i></button></p>
            <div id="nav-container" class="nav-container" v-show="showNav">
              <ul class="nav nav-main">

                <li v-if="!setUpComplete" class="nav-item"><router-link :to="setUpNextRoute" class="nav-link" exact-active-class="active">Continue Set Up</router-link></li>

                <li v-if="setUpComplete && isSuperAdmin" class="nav-item"><router-link to="/stats" class="nav-link" exact-active-class="active">Stats</router-link></li>

                <li v-if="customer && customer.location_count" class="nav-item"><router-link to="/locations" class="nav-link" exact-active-class="active">Locations</router-link></li>

                <li v-if="customer && customer.has_users" class="nav-item"><router-link to="/team" class="nav-link" exact-active-class="active">Team</router-link></li>

                <li v-if="setUpComplete && isSuperAdmin" class="nav-item"><router-link to="/rewards" class="nav-link" exact-active-class="active">Rewards</router-link></li>

                <li class="nav-item dropdown">
                  <a @click.prevent="e => e.target.classList.toggle('show')" class="nav-link dropdown-toggle" href="#account">Account</a>
                  <ul class="list-unstyled dropdown-menu dropdown-menu-app">
                    <li class="dropdown-item"><router-link to="/" exact-active-class="active">User Account</router-link></li>
                    <li v-if="this.customer && this.customer.group_limit && custom_claims && custom_claims.adminLevel == 'superAdmin'" class="dropdown-item"><router-link to="/billing" exact-active-class="active">Billing</router-link></li>
                    <li v-if="customer && customer.role_count" class="dropdown-item"><router-link to="/roles" exact-active-class="active">Team Roles</router-link></li>
                    <li v-if="customer" class="dropdown-item"><router-link to="/open-dental" exact-active-class="active">Open Dental Connection</router-link></li>
                    <li v-if="customer" class="dropdown-item"><router-link to="/badges" exact-active-class="active">Badges</router-link></li>
                    <!-- <li v-if="customer && customer.useClinics === false" class="dropdown-item"><router-link to="/open-dental" exact-active-class="active">Open Dental Connection</router-link></li> -->
                    <!-- <li class="dropdown-item"><a>Google Reviews Connection</a></li> -->
                    <li class="dropdown-item"><a href="mailto:smiley-support@hey-smiley.com">Email Support</a></li>
                    <li class="dropdown-item"><a href="#log-out" @click.prevent="logOut">Log Out</a></li>
                  </ul>
                </li>

                

              </ul>
            </div> <!-- /.nav-container -->
          </div> <!-- /.col -->
        </div> <!-- /.row -->
      </div> <!-- /.container -->
    </div> <!-- /.header-private -->

    <div class="container">
      <div class="row row-content">
        <div class="col">
          <slot></slot>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->

  </div> <!-- /.layout-private -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'

export default {
  props: ['customer'],
  data () {
    return {
      showNav: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'custom_claims']),
    isSuperAdmin() {
      return this.custom_claims.adminLevel =="superAdmin";
    },
    // hide setup link if not a super admin user or all conditions met
    setUpComplete() {
      if((this.custom_claims && this.custom_claims.adminLevel && this.custom_claims.adminLevel !=="superAdmin") || (this.custom_claims && this.customer && this.customer.group_limit && this.customer.group_count && this.customer.location_count && this.customer.role_count && this.customer.has_users)) {
        return true;
      }
      return false;
    },
    setUpNextRoute () {
      // step 1 - if no subscription --> subscription page
      if(!this.custom_claims || !this.customer || !this.customer.group_limit) {
        return "/subscription";
      }

      // step 2 - if no locations or groups --> groups & locations page
      if(!this.customer.group_count || !this.customer.location_count) {
        return "/locations"
      }

      // step 3 - if no opendental connection --> connect opendental page
        // check open_dental_status property on the account object.
          // updated to active or error by connection tests & functions
          if(!this.customer.open_dental_status || this.customer.open_dental_status === "error") {
        return "/open-dental"
      }

      // step 4 - if no team roles --> create roles page
      if(!this.customer.role_count) {
        return "/roles"
      }

      // step 5 - if no team members --> add team members page
      if(!this.customer.has_users) {
        return "/team"
      }

      // fallback
      else {
        return '/'
      }
    },
  },
  methods: {
    logOut () {
      this.$firebase.logOut()
    },
    toggleNav () {
      this.showNav = !this.showNav;
    },
  },
  mounted () {

  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.header-private {
  background-color: @color-blue;
  padding: 35px 0;
  margin-bottom: 50px;
}
.title-short {
  margin: 0;
}
.col-title {
  margin-bottom: 25px;
}
</style>
