<template lang="html">
  <div v-if="badge && badge.badge_icon && badge.badge_color" class="badge-container">
    <button
      @click="showTooltip=!showTooltip"
      :class="badgeSize"
      :style="{'background-color': badge.badge_color}"
      class="badge d-flex align-items-center justify-content-center text-center"
    >
      <i class="fas" :class="badge.badge_icon"></i>
      <span v-if="showTooltip && badge.badge_name && !disableTooltip" class="badge-tooltip">
        {{ badge.badge_name }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: ['size','badge','disableTooltip'],
  data () {
    return {
      showTooltip: false,
    }
  },
  computed: {
    // fall back to sm if size isn't set
    badgeSize() {
      if(this.size) {
        return this.size;
      };
      return 'sm';
    },
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.badge-container {
  display: inline-block;
}
.badge {
  // resets
  line-height: 1em;
	padding: 0;
  margin: 0;
	text-decoration: none;
	box-shadow: none !important;
  outline: none;
  // styles
  position: relative; // set here so badge-container can be absolutely positioned outside component
  border-radius: 80px;
  border: 2px solid @dark-color;
  color: @dark-color;

  &:hover {
    border-color: @light-color;
  }
}
.badge.sm {
  width: 22px;
  height: 22px;
  font-size: 10px;
}
.badge.lg {
  width: 41px;
  height: 41px;
  font-size: 20px;
}
.badge-tooltip {
  position: absolute;
  z-index: 200;
  padding: 10px 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  color: @light-color;
  background-color: @dark-color;;
  width: 160px;
  margin-top: 4px; // space for arrow
  text-align: center;
  box-shadow: 0 0 5px 0 rgba(255,255,255,0.2);
  // box-shadow: 0 0 5px 0 @color-blue;

  // arrow
  &:before {
    content: '';
    // arrow left
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid @dark-color;
    position: absolute;
    top: -4px;
    left: 50%;
    margin-left: -4px;
  }
}
.badge.sm .badge-tooltip {
  top: 21px;
}
.badge.lg .badge-tooltip {
  top: 41px;
}
</style>
