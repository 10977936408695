import store from './store'
import router from './router'
import Firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/analytics'
import 'firebase/compat/database'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

const config = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);
let redirectUrl;

if(process.env.NODE_ENV === "production") {
  redirectUrl = 'https://admin.hey-smiley.com/log-in';
}
// staging has both NODE_ENV production and Vue_APP_ENV, so this overwrites and must come 2nd
if(process.env.VUE_APP_ENV === "staging") {
  redirectUrl = 'https://smiley-staging.web.app//log-in';
}
if(process.env.NODE_ENV === "development" || process.env.VUE_APP_ENV === "test") {
  redirectUrl = "http://localhost:8080/log-in"
}

// via https://medium.com/@MichalBujalski/reactive-firebase-auth-with-vue-92577ab78ff6
export default {
  install: (Vue) => {
    //  store as global properties for easy access
    Vue.firebase = Firebase.initializeApp(config)
    Vue.auth = Vue.firebase.auth()
    Vue.firestore = Vue.firebase.firestore()
    Vue.analytics = Vue.firebase.analytics()
    Vue.functions = Vue.firebase.functions()
    Vue.storage = Vue.firebase.storage()
    // Vue.db = Vue.firebase.database()

    // init firebase emulators
    // console.log('node env',process.env.NODE_ENV)
    if (location && location.hostname === "localhost" && process.env.VUE_APP_ENV === "test") {
    // initialize emulators for local development when not running on npm localhost
    // if (location.hostname === "localhost" && location.port !== '8080') {
      // auth must use url format
      Vue.auth.useEmulator("http://localhost:9099");
      // firestore must use separate host & port format
      Vue.firestore.useEmulator("localhost", 8081);
      Vue.functions.useEmulator("localhost", 5001);
      Vue.storage.useEmulator("localhost", 9199);
      // allow Cypress to connect to Firestore:
        // https://github.com/cypress-io/cypress/issues/6350
      Vue.firestore.settings({
        experimentalForceLongPolling: true,
        merge: true
      });
    }

    // Google Auth Provider
    Vue.googleAuth = new Firebase.auth.GoogleAuthProvider()
    Vue.googleAuth.addScope('https://www.googleapis.com/auth/userinfo.email')
    Vue.googleAuth.addScope('https://www.googleapis.com/auth/userinfo.profile')

    // TODO: Google Auth scopes for Google reviews feature
    // https://developers.google.com/my-business/content/implement-oauth
    // Vue.googleAuth.addScope('https://www.googleapis.com/auth/business.manage')

    const redirectSettings = {
      url: redirectUrl
    }
    Vue.prototype.$firebase = {
      logIn: async (email, password) => {
        return await Vue.auth.signInWithEmailAndPassword(email, password)
          .catch((err) => {
            // console.log(err);
            return err
          });
      },
      logOut: async () => {
        return await Vue.auth.signOut()
          .then(() => {
            store.dispatch('resetState');
            router.push('/log-in')
          })
          .catch((err) => {
            // console.log(err);
            return err
          });
      },
      signUp: async (email, password) => {
        let uid;
        return await Vue.auth.createUserWithEmailAndPassword(email, password)
          // after user created, send email verification
          .then(userCredential => {
            uid = userCredential.user.uid;
            // console.log("practice user saved",uid);
          })
          .catch((err) => {
            // console.log(err);
            return err
          });
      },
      resetPassword: async (email) => {
        // console.log(resetPasswordRedirectSettings)
        return await Vue.auth.sendPasswordResetEmail(email, redirectSettings)
          .then(() => {
            return true
          })
          .catch((err) => {
            // console.log(err);
            return err
          });
      },
      verifyEmail: async () => {
        let user = Vue.auth.currentUser;
        return await user.sendEmailVerification(redirectSettings)
          .then(() => {
            // console.log("email sent");
            return true
          })
          .catch((err) => {
            // console.log(err);
            return false
          });
      },
      logInWithGoogle: async () => {
        // use redirect only for test environment
        if(process.env.VUE_APP_ENV === "test") {
          return await Vue.auth.signInWithRedirect(Vue.googleAuth)
            .catch((err) => {
                // console.log(err);
                return err
            });
        } else {
          return await Vue.auth.signInWithPopup(Vue.googleAuth)
            .then((result) => {
              // var credential = result.credential;
              // This gives you a Google Access Token. You can use it to access the Google API.
              // var token = credential.accessToken;
              // console.log(`google token: ${result.credential.accessToken}`)
              // The signed-in user info.
              // var user = result.user;
            })
            .catch((err) => {
              // console.log(err);
              return err
            });
        }
      },
    }
    Vue.auth.onAuthStateChanged( async user => {
      // console.log('USER',user)
      store.dispatch('updateUser', user);
      if(user) {
        let userPropsRef = Vue.firestore.collection('users').doc(user.uid);
        store.dispatch('setUserPropsRef', userPropsRef);
        // track user in analytics
        Vue.analytics.setUserId(user.uid);
      }
    })
  }
}
