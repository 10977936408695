<template lang="html">
  <div class="layout-public">

    <div class="header-public">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <router-link to="/log-in" class="logo">
              <img src="../assets/img/logo-smiley.svg" alt="smiley" width="94px" height="30px">
            </router-link>
          </div> <!-- /.col -->
        </div> <!-- /.row -->
      </div> <!-- /.container -->
    </div> <!-- /.header-public -->

    <div class="container">
      <div class="row">
        <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">

          <hr />
          <slot></slot>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->

  </div> <!-- /.layout-public -->
</template>

<script>
export default {
  props: ['practiceName'],
  methods: {

  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.header-public {
  background-color: @color-blue;
  padding: 35px 0;
  margin-bottom: 50px;
}

</style>
