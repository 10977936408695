import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

import AccountView from './views/AccountView.vue'
import LogInView from './views/LogInView.vue'
import SignUpView from './views/SignUpView.vue'
import ResetPasswordView from './views/ResetPasswordView.vue'
import SubscriptionView from './views/SubscriptionView.vue'
import BillingView from './views/BillingView.vue'
// import Both_ClinicsOption from './views/Both_ClinicsOption.vue'
import Locations from './views/Locations.vue'
import NoClinics_OpenDental from './views/NoClinics_OpenDental.vue'
import Roles from  './views/Roles.vue'
import Team from  './views/Team.vue'
import Invite from  './views/Invite.vue'
import StatsView from  './views/StatsView.vue'
import RewardsView from './views/RewardsView.vue'
import RewardsLeaderboardView from './views/RewardsLeaderboardView.vue'
import RewardsListView from './views/RewardsListView.vue'
import LicenseView from './views/LicenseView.vue'
import BadgeSetup from './views/BadgeSetup.vue'
import ProviderProfileView from './views/ProviderProfileView.vue'

Vue.use(Router)

const routes = [
  // Public
  // ----------
  {
    path: '/log-in',
    name: 'logIn',
    component: LogInView,
    meta: {
      pageTitle: 'Log In',
    }
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: SignUpView,
    meta: {
      pageTitle: 'Sign Up',
    }
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordView,
    meta: {
      pageTitle: 'Reset Password',
    }
  },
  {
    path: '/invite',
    name: 'invite',
    component: Invite,
    meta: {
      pageTitle: 'Join Your Team on Smiley',
    }
  },
  {
    path: '/license',
    name: 'license',
    component: LicenseView,
    meta: {
      pageTitle: 'Smiley App License',
    }
  },
  // --------------------
  // Private
  // --------------------
  {
    path: '/',
    name: 'account',
    component: AccountView,
    meta: {
      pageTitle: 'Account',
      authRequired: true,
    }
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: SubscriptionView,
    meta: {
      pageTitle: 'Subscription',
      authRequired: true,
    }
  },
  {
    path: '/billing',
    name: 'billing',
    component: BillingView,
    meta: {
      pageTitle: 'Billing',
      authRequired: true,
    }
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsView,
    meta: {
      pageTitle: 'Stats',
      authRequired: true,
    }
  },
  {
    path: '/badges',
    name: 'badges',
    component: BadgeSetup,
    meta: {
      pageTitle: 'Badges',
      authRequired: true,
    }
  },
  // Setup Pages
  // ----------
  // {
  //   path: '/setup-clinics-option',
  //   name: 'setupClinicsOption',
  //   component: Both_ClinicsOption,
  //   meta: {
  //     pageTitle: 'Setup: Clinics Option',
  //     authRequired: true,
  //   }
  // },
  {
    path: '/locations',
    name: 'Locations',
    component: Locations,
    meta: {
      pageTitle: 'Groups & Locations',
      authRequired: true,
    }
  },
  {
    path: '/open-dental',
    name: 'NoClinics_OpenDental',
    component: NoClinics_OpenDental,
    meta: {
      pageTitle: 'Connect Open Dental',
      authRequired: true,
    }
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    meta: {
      pageTitle: 'Create Roles',
      authRequired: true,
    }
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    meta: {
      pageTitle: 'Team',
      authRequired: true,
    }
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: RewardsView,
    meta: {
      pageTitle: 'Rewards',
      authRequired: true,
    },
    children: [
      {
        path: '/rewards',
        name: 'RewardsLeaderboard',
        component: RewardsLeaderboardView,
        meta: {
          pageTitle: 'Rewards',
          authRequired: true,
        },
      },
      {
        path: '/rewards-list',
        name: 'RewardsList',
        component: RewardsListView,
        meta: {
          pageTitle: 'Rewards List',
          authRequired: true,
        },
      },
    ]
  },
  {
    path: '/provider/:id',
    name: 'Provider Profile',
    component: ProviderProfileView,
    meta: {
      pageTitle: 'Provider Profile',
      authRequired: true,
    }
  },
  // Lazy loaded views
  // ----------
    // route level code-splitting
    // this generates a separate chunk ([name].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },

  // ----------
  // 404
  // explicit path used for redirects
  // {
  //   path: "/page-not-found",
  //   name: '404',
  //   component: PageNotFound,
  //   meta: {
  //     pageTitle: 'Page Not Found',
  //   }
  // },
  // // catchall path
  // {
  //   path: "*",
  //   component: PageNotFound,
  //   meta: {
  //     pageTitle: 'Page Not Found',
  //   }
  // }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // always scroll to top of new page
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  // if page requires authentication
  if ( to.matched.some(record => record.meta.authRequired) ) {
    // if no user and attempting to view invite, take to signup page
    if (!store.state.user && to.name == "invite") {
      next({
        path: '/sign-up',
        query: { redirect: to.fullPath }
      })
    }
    // if no user, force login
    else if (!store.state.user) {
      next({
        path: '/log-in',
        query: { redirect: to.fullPath }
      })
    }
    else {
      // console.log('passed route reqs')
      next()
    }
  }
  else {
    // console.log('no route reqs')
    next()
  }
})

// update page title
const DEFAULT_TITLE = 'Smiley Admin';
router.afterEach((to, from) => {
  if(to.meta.pageTitle) {
    document.title = `${to.meta.pageTitle} | ${DEFAULT_TITLE}`;
  } else {
    document.title = DEFAULT_TITLE;
  }
  // track in GA
  Vue.analytics.logEvent('page_view');
});

export default router
