<template>
  <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">

    <div class="hero-rewards"></div> <!-- /.hero-rewards -->

    <div v-if="users1Scaling" class="u-group users1Scaling row">
      <div class="col-12">
        <div class="u-group-title d-flex flex-row justify-content-between">
          <h2>Scaling the Way</h2>
          <p><img src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /></p>
        </div> <!-- /.u-group-title -->
        <div v-if="users1Scaling[0]" class="winner winner-first d-flex flex-row justify-content-between">
          <p><img src="../assets/img/icon-rewards-winner-first@2x.png" width="49px" height="46px" alt="1" /></p>
          <ul class="list-unstyled flex-grow-1">
            <li v-for="u in users1Scaling[0].values" :class="{currentU: u.uid==user.uid}"><strong>{{ u.name | limitStringLength(30) }}</strong></li>
          </ul>
          <p>{{ users1Scaling[0].key }}</p>
        </div> <!-- /.winner -->
        <div v-if="users1Scaling[1]" :class="{currentU: users1Scaling[1].uid==user.uid}" class="winner winner-second d-flex flex-row justify-content-between">
          <p><img src="../assets/img/icon-rewards-winner-second@2x.png" width="49px" height="46px" alt="1" /></p>
          <ul class="list-unstyled flex-grow-1">
            <li v-for="u in users1Scaling[1].values" :class="{currentU: u.uid==user.uid}"><strong>{{ u.name | limitStringLength(30) }}</strong></li>
          </ul>
          <p>{{ users1Scaling[1].key }}</p>
        </div> <!-- /.winner -->
        <div v-if="users1Scaling[2]" :class="{currentU: users1Scaling[2].uid==user.uid}" class="winner winner-third d-flex flex-row justify-content-between">
          <p><img src="../assets/img/icon-rewards-winner-third@2x.png" width="49px" height="46px" alt="1" /></p>
          <ul class="list-unstyled flex-grow-1">
            <li v-for="u in users1Scaling[2].values" :class="{currentU: u.uid==user.uid}"><strong>{{ u.name | limitStringLength(30) }}</strong></li>
          </ul>
          <p>{{ users1Scaling[2].key }}</p>
        </div> <!-- /.u -->
        <hr />
        <div v-if="users1Scaling[3]" :class="{currentU: users1Scaling[3].uid==user.uid}" class="u-item d-flex flex-row justify-content-between">
          <!-- <p>{{ users1Scaling[3].name | limitStringLength(30) }}</p> -->
          <ul class="list-unstyled">
            <li v-for="u in users1Scaling[3].values" :class="{currentU: u.uid==user.uid}">{{ u.name | limitStringLength(30) }}</li>
          </ul>
          <p>{{ users1Scaling[3].key }}</p>
        </div> <!-- /.u -->
        <div v-if="users1Scaling[4]" :class="{currentU: users1Scaling[4].uid==user.uid}" class="u-item d-flex flex-row justify-content-between">
          <!-- <p>{{ users1Scaling[4].name | limitStringLength(30) }}</p> -->
          <ul class="list-unstyled">
            <li v-for="u in users1Scaling[4].values" :class="{currentU: u.uid==user.uid}">{{ u.name | limitStringLength(30) }}</li>
          </ul>
          <p>{{ users1Scaling[4].key }}</p>
        </div> <!-- /.u -->
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div v-if="users2Leading" class="u-group users2Leading row">
      <div class="col-12">
        <div class="u-group-title d-flex flex-row justify-content-between">
          <h2>Leading the Way</h2>
          <p><img src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /></p>
        </div> <!-- /.u-group-title -->
        <div v-for="list in users2Leading" class="u-item d-flex flex-row justify-content-between">
          <ul class="list-unstyled">
            <li v-for="u in list.values" :class="{currentU: u.uid==user.uid}">{{ u.name | limitStringLength(30) }}</li>
          </ul>
          <p>{{ list.key }}</p>
        </div> <!-- /.list -->
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div v-if="users3Growing" class="u-group users3Growing row">
      <div class="col-12">
        <div class="u-group-title d-flex flex-row justify-content-between">
          <h2>Growing the Way</h2>
          <p><img src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /></p>
        </div> <!-- /.u-group-title -->
        <div v-for="list in users3Growing" class="u-item d-flex flex-row justify-content-between">
          <ul class="list-unstyled">
            <li v-for="u in list.values" :class="{currentU: u.uid==user.uid}">{{ u.name | limitStringLength(30) }}</li>
          </ul>
          <p>{{ list.key }}</p>
        </div> <!-- /.list -->
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div v-if="users4Delivering" class="u-group users4Delivering row">
      <div class="col-12">
        <div class="u-group-title d-flex flex-row justify-content-between">
          <h2>Delivering the Way</h2>
          <p><img src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /></p>
        </div> <!-- /.u-group-title -->
        <div v-for="list in users4Delivering" class="u-item d-flex flex-row justify-content-between">
          <ul class="list-unstyled">
            <li v-for="u in list.values" :class="{currentU: u.uid==user.uid}">{{ u.name | limitStringLength(30) }}</li>
          </ul>
          <p>{{ list.key }}</p>
        </div> <!-- /.list -->
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div v-if="users5Living" class="u-group users5Living row">
      <div class="col-12">
        <div class="u-group-title d-flex flex-row justify-content-between">
          <h2>Living the Way</h2>
          <p><img src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /></p>
        </div> <!-- /.u-group-title -->
        <div v-for="list in users5Living" class="u-item d-flex flex-row justify-content-between">
          <ul class="list-unstyled">
            <li v-for="u in list.values" :class="{currentU: u.uid==user.uid}">{{ u.name | limitStringLength(30) }}</li>
          </ul>
          <p>{{ list.key }}</p>
        </div> <!-- /.list -->
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div v-if="userListNoPoints && userListNoPoints.length" class="u-group u-group-zero row">
      <div class="col-12">
        <div class="u-group-title">
          <h2>Zero Points</h2>
        </div> <!-- /.u-group-title -->
        <div class="row u-items-zero">
          <div v-for="u in userListNoPoints" :class="{currentU: u.uid==user.uid}" class="u-item col-4">
            <p>{{ u.name | limitStringLength(30) }}</p>
          </div> <!-- /.u -->
        </div>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div v-if="userListAdmins && userListAdmins.length" class="u-group u-group-zero row">
      <div class="col-12">
        <div class="u-group-title d-flex flex-row justify-content-between">
          <h2>Admins</h2>
          <p><img src="../assets/img/icon-reward-smiley@2x.png" width="20px" height="20px" alt="" /></p>
        </div> <!-- /.u-group-title -->
        <div v-for="u in userListAdmins" :class="{currentU: u.uid==user.uid}" class="u-item d-flex flex-row justify-content-between">
          <p>{{ u.name | limitStringLength(30) }}</p>
          <p>{{ u.points }}</p>
        </div> <!-- /.u -->
      </div> <!-- /.col -->
    </div> <!-- /.row -->

  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import ListErrors from '../components/ListErrors.vue'

export default {
props: ['customer'],
components: {
  ListErrors
},
data () {
  return {
    errors: [],
    loading: false,
    userList: [],
    userListNoPoints: [],
    userListAdmins: [],
  }
},
computed: {
  ...mapGetters(['user', 'user_props', 'custom_claims', 'custom_claims']),
  userSlice() {
    // after removing top 5, calculate 10% of remaining users
    return Math.ceil((this.userList.length-5) * .1);
  },
  // userList is already in decending order
  // get top 5
  users1Scaling() {
    if(this.userList && this.userList.length) {
      return this.userList.slice(0,5); // end not included in returned value, only 0-4
    }
    return null;
  },
  // get next 10%
  users2Leading() {
    if(this.userList && this.userList.length && this.userSlice && this.userList.length >= 5) {
      return this.userList.slice(5,(5+this.userSlice));
    }
    return null;
  },
  // get next 20%
  users3Growing() {
    if(this.userList && this.userList.length && this.userSlice && this.userList.length >= (5+this.userSlice)) {
      return this.userList.slice((5+this.userSlice),(5+this.userSlice*3));
    }
    return null;
  },
  // get next 20%
  users4Delivering() {
    if(this.userList && this.userList.length && this.userSlice && this.userList.length >= (5+this.userSlice*3)) {
      return this.userList.slice((5+this.userSlice*3),(5+this.userSlice*5));
    }
    return null;
  },
  // get rest
  users5Living() {
    if(this.userList && this.userList.length && this.userSlice && this.userList.length >= (5+this.userSlice*5)) {
      return this.userList.slice((5+this.userSlice*5));
    }
    return null;
  },
},
watch: {
  custom_claims () {
    this.loadUsers();
  },
},
methods: {
  // group values of an array of objects by key into array of objects with nested array of matches
  // via https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects#comment64856953_34890276
  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) { el.values.push(x); }
      else { rv.push({ key: v, values: [x] }); }
      return rv;
    }, []);
  },
  loadUsers() {
    if(this.custom_claims && this.custom_claims.customerId && !this.userList.length) {
      // console.log('loadUsers')
      Vue.firestore.collection('users')
        .where('customerId', '==', this.custom_claims.customerId)
        .where('status', '==', 'accepted')
        // .where('adminLevel', '==', 'mobileOnly')
        // .where('points', '>', 0)
        // .orderBy('points','desc') // important for leaderboard sorting, but omits
        .get()
        .then(usersQuerySnap => {
          // console.log(usersQuerySnap.docs)
          if(!usersQuerySnap.empty) {
            usersQuerySnap.docs.forEach(uSnap => {
              if(uSnap.data().points && uSnap.data().points > 0 && uSnap.data().adminLevel == "mobileOnly") {
                this.userList.push({
                  name: uSnap.data().name || uSnap.data().email,
                  points: uSnap.data().points,
                  uid: uSnap.id,
                })
              } else if(!uSnap.data().points || uSnap.data().points == 0){
                this.userListNoPoints.push({
                  name: uSnap.data().name || uSnap.data().email,
                  uid: uSnap.id,
                })
              }
              else if(uSnap.data().adminLevel !== "mobileOnly"){
                this.userListAdmins.push({
                  name: uSnap.data().name || uSnap.data().email,
                  uid: uSnap.id,
                  points: uSnap.data().points,
                })
              }
            });
          }
          // sort since we removed orderBy in query for admin
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
            // compareFn to sort in ascending order, returns negative number if prev < next, positive if prev > next
            // so desc order is (next - prev) instead of (prev - next)
          this.userList = this.groupBy(this.userList,'points');
          this.userList.sort((prev, next) => next.key - prev.key);
          if(this.userListAdmins && this.userListAdmins.length) {
            this.userListAdmins.sort((prev, next) => next.points - prev.points);
          }
        })
        .catch(err => console.log(err));
    }
    
  },
},
mounted () {
  this.loadUsers();
},
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.hero-rewards {
  height: 164px;
  background: url('../assets/img/bg-rewards-trophy@2x.png') center bottom no-repeat;
  // background-size: 416px 416px; // mobile
  background-size: 164px 164px;
  margin: 0 0 -30px;
  position: relative;
  z-index: 5;
}
.u-group {
  margin-bottom: 5px;
  padding: 30px 0;

  > .col-12 {
    padding: 0 30px;
  }

  h2, p {
    margin: 0;
  }
  hr {
    border-top: 1px solid @dark-color;
    margin: 0 0 25px 0;
  }

  .u-group-title {
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid @dark-color;
  }
  .winner,
  .u-item {
    margin-bottom: 25px;

    .list-unstyled {
      margin: 0;
      padding: 0;
    }
  }
  .currentU {
    // background-color: @color-blue;
    // color: @light-color;
    border: 1px solid @dark-color;
    background-color: fadeout(@light-color,20%);
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0 -10px 15px;
  }
  .winner {
    p img {
      margin-right: 10px;
      vertical-align: top;
    }
    p, ul li {
      height: 50px;
      line-height: 50px;
      vertical-align: middle;
    }
  }
  .u-item:last-child {
    margin: 0
  }
}
.users1Scaling.u-group {
  background-color: fadeout(@color-magenta,90%);
  border: 5px solid @color-magenta;
  padding: 25px 0;

  > .col-12 {
    padding: 0 25px;
  }
}
.users2Leading.u-group {
  background-color: @color-green;
}
.users3Growing.u-group {
  background-color: @color-yellow;
}
.users4Delivering.u-group {
  background-color: @color-orange;
}
.users5Living.u-group {
  background-color: @color-salmon;
}
.u-group-zero {
  > .col-12 {
    padding: 0;
  }
}
.u-items-zero .u-item {
  // margin: 0 20px 20px 0;
  padding-right: 20px;
  overflow: hidden;
}
.u-items-zero .u-item.currentU {
  margin: 0 0 15px;
}
</style>
